import * as types from "./types";
import {
    dealerChanged,
    roundIdChanged,
    gameSoundChanged,
    initialState,
    playersCountChanged,
    totalBetsAmount,
    error
} from "./reducerFunctions";

export const INITIAL_STATE = {
    roundId: "",
    startTime: null,
    dealerNickname: "",
    totalBet: 0,
    tableName: "",
    streamData: null
};

export default function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case types.INITIAL_STATE: {
            return initialState({ payload });
        }
        case types.ROUND_ID_CHANGED: {
            return roundIdChanged({ payload, state });
        }
        case types.GAME_SOUND_CHANGED: {
            return gameSoundChanged({ payload, state });
        }
        case types.DEALER_CHANGED: {
            return dealerChanged({ payload, state });
        }
        case types.PLAYERS_COUNT_CHANGED: {
            return playersCountChanged({ payload, state });
        }
        case types.TOTAL_BETS_CHANGED: {
            return totalBetsAmount({ payload, state });
        }
        case types.ERROR: {
            return error({ payload, state });
        }
    }
}
