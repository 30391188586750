/**
 * returns sum of user sideBets , calculated from sideBets object
 * @param sideBets
 * @returns {number}
 */
import { SIDE_BETS } from "shared/constants";

export function getSumFixedToThree() {
    let sum = 0;
    for (let i = 0; i < arguments.length; i++) {
        sum += Number(arguments[i]);
    }
    return Number(sum.toFixed(3));
}

export const calculateSideBetsSum = sideBets => {
    let sum = 0;
    for (let betKey in sideBets) {
        sum = getSumFixedToThree(sum, sideBets[betKey]);
    }
    return sum;
};

/**
 * calculates and returns computed data of the same user sitting on different boxes
 * @param previousBoxesAmounts
 * @param currentBox
 * @returns {{insurance: number, baseBets: number, splitAmount: *, totalBet: *, sideBetWin: number, doubleAmount: number}}
 */

export const getPlayerBoxesComputedData = (previousBoxesAmounts, currentBox) => {
    const sideBetsSum = calculateSideBetsSum(currentBox.sideBets);
    const sideBetsWin = calculateSideBetsSum(currentBox.sideBetsWin);
    const totalBets = calculateTotalBet(currentBox);
    const baseBets = calculateBaseBets(currentBox);
    return {
        sideBets: getSumFixedToThree(sideBetsSum, previousBoxesAmounts.sideBets),
        totalBet: getSumFixedToThree(previousBoxesAmounts.totalBet, totalBets),
        baseBets: getSumFixedToThree(previousBoxesAmounts.baseBets, baseBets),
        sideBetsWin: getSumFixedToThree(previousBoxesAmounts.sideBetsWin, sideBetsWin),
        doubleAmount: getSumFixedToThree(previousBoxesAmounts.doubleAmount, currentBox.doubleAmount),
        insurance: getSumFixedToThree(previousBoxesAmounts.insurance, currentBox.insurance),
        splitAmount: getSumFixedToThree(previousBoxesAmounts.splitAmount, currentBox.splitAmount)
    };
};

function calculateBaseBets(box) {
    return getSumFixedToThree(box.betAmount1, box.betAmount2);
}

export const getNestedRowData = box => {
    const sideBetsSum = calculateSideBetsSum(box.sideBets);
    const baseBets = calculateBaseBets(box);
    const totalBet = calculateTotalBet(box);
    const sideBetsWin = calculateSideBetsSum(box.sideBetsWin);
    return {
        ...box,
        playerName: `Box ${box.boxId + 1}`,
        partnerName: "-",
        baseBets,
        totalBet,
        sideBets: sideBetsSum,
        sideBetsWin,
        isBox: true,
        key: box.boxId
    };
};

/**
 *
 * @param box
 */
function calculateTotalBet(box) {
    const sideBetsSum = calculateSideBetsSum(box.sideBets);
    return getSumFixedToThree(
        sideBetsSum,
        box.splitAmount,
        box.doubleAmount,
        box.insurance,
        box.betAmount1,
        box.betAmount2
    );
}

const getChildrenDataByBox = box => {
    const { sideBets, sideBetsWin } = box;
    const children = [];

    for (let sideBetKey in sideBets) {
        children.push({
            sideBets: `${SIDE_BETS[sideBetKey]} : ${sideBets[sideBetKey]}`,
            sideBetsWin: sideBetsWin[sideBetKey] || "-"
        });
    }
    return children;
};

/**
 *
 * @param boxes
 * @returns []
 */
export const getPlayersDataByUser = boxes => {
    if (!boxes) return [];
    const dataByUser = {};
    for (let box of boxes) {
        const nestedRowData = getNestedRowData(box);

        const sideBetsSum = calculateSideBetsSum(box.sideBets);
        const sideBetsWin = calculateSideBetsSum(box.sideBetsWin);
        const totalBet = calculateTotalBet(box);
        const baseBets = calculateBaseBets(box);
        if (dataByUser[box.playerId]) {
            const previousBox = dataByUser[box.playerId];

            dataByUser[box.playerId] = {
                ...previousBox,
                children: [...previousBox.children, nestedRowData],
                ...getPlayerBoxesComputedData(previousBox, box)
            };
        } else {
            dataByUser[box.playerId] = {
                ...box,
                sideBets: sideBetsSum,
                sideBetsWin,
                totalBet,
                baseBets,
                children: [nestedRowData],
                isUser: true,
                key: box.playerId
            };
        }
    }

    return Object.values(dataByUser);
};

export const getPlayersDataByBox = boxes => {
    return boxes.map(box => {
        const sideBetsSum = calculateSideBetsSum(box.sideBets);
        const children = getChildrenDataByBox(box);
        const totalBet = calculateTotalBet(box);
        const baseBets = calculateBaseBets(box);
        const sideBetsWin = calculateSideBetsSum(box.sideBetsWin);

        return {
            ...box,
            boxId: `${box.boxId + 1}`,
            children: children.length ? children : null,
            sideBets: sideBetsSum,
            totalBet,
            baseBets,
            sideBetsWin,
            key: box.boxId
        };
    });
};

export const getUpdatedBoxes = (prevBoxes, currentData, changeKey) => {
    return prevBoxes.map(box => {
        if (box.boxId === currentData.boxId) {
            return { ...box, [changeKey]: getSumFixedToThree(box[changeKey], currentData.amount) };
        }
        return box;
    });
};
/**
 *
 * @param boxes
 * @param userInfo {playerId:number , boxId:number}
 * @returns {*}
 */
export const removePlayerFromBox = (boxes, removedUserBoxId) => boxes.filter(box => box.boxId !== removedUserBoxId);

/**
 *
 * @param boxes
 * @param playerData(full box data)
 * @returns {this}
 */
export const addPlayerToBox = (boxes, playerData) =>
    [...boxes, playerData].sort((box, nextBox) => box.boxId - nextBox.boxId);

/**
 *
 * @param boxes
 * @param sideBetData
 * @returns {*}
 */
export const updateSideBets = (boxes, sideBetData) =>
    boxes.map(box => {
        if (box.boxId === sideBetData.boxId) {
            const updatedSideBet = sideBetData.sideBet;
            return {
                ...box,
                sideBets: {
                    ...box.sideBets,
                    [updatedSideBet]: sideBetData.amount
                }
            };
        }
        return { ...box };
    });
/**
 *
 * @param boxes
 * @param sideBetsWinData {bisId ,playerId , sideBet , amount }
 * @returns Array<Box>
 */

export const getBoxesWithUpdatedSideBetWins = (boxes, sideBetsWinData) => {
    return boxes.map(box => {
        const updatedSideBet = sideBetsWinData.sideBet;
        if (box.boxId === sideBetsWinData.boxId) {
            return {
                ...box,
                sideBetsWin: {
                    ...box.sideBetsWin,
                    [updatedSideBet]: sideBetsWinData.amount
                }
            };
        }
        return box;
    });
};

/**
 *
 * @param reasons (object)
 * @returns Array <{id: number, value: string}>
 */
export function getBlockUserReasons(reasons) {
    if (!reasons) {
        return [];
    }
    let reasonsList = [];
    for (let key in reasons) {
        reasonsList = [...reasonsList, { id: key, value: reasons[key] }];
    }
    return reasonsList;
}
