export function createExpandedMetadata(data) {
    const result = [];
    for (const betType in data) {
        if (data.hasOwnProperty(betType)) {
            result.push({ betType, bet: data[betType] });
        }
    }
    return result;
}

export function renderDealerName(dealerName, record) {
    const dealerNickname = record.dealerNickname;
    return `${dealerName} ${dealerNickname ? `(${dealerNickname})` : ""}`;
}
