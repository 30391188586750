import React, { memo, useCallback, lazy, Suspense } from "react";
import styles from "./Game.module.scss";
import Footer from "components/Footer/Footer";
import PropTypes from "prop-types";
import GameSettings from "components/GameSettings/GameSettings";
import Mistakes from "components/Mistakes/Mistakes";
import ViewersPlayers from "@livecasino/core/v2/components/ViewersPlayersBetters";
import classNames from "classnames";
import useVolume from "@livecasino/core/hooks/useVolume";
import useCountdown from "@livecasino/core/hooks/useCountdown";
import useVideo from "hooks/useVideo";
import { ACTION_STATES, STREAM_SOURCES, THUMBNAIL_IMAGES_URL } from "../../shared/constants";
import queryString from "query-string";
import { getConstructedMistakeData } from "../../shared/utils";
import GameMutedInfo from "../GameMutedInfo";
import TablePicture from "../TablePicture";

const NanoVideoPlayer = lazy(() => import("../NanoVideoPlayer"));
const LocalStreamPlayer = lazy(() => import("../LocalStreamPlayer"));

const Game = ({
    tableId,
    roundId = null,
    tableName = "",
    gameName = "",
    dealerNickname,
    selected = null,
    onVideoZoom = null,
    isVideoZoomed = null,
    flagVersion = null,
    languageCode = null,
    htmlId = "HTML_DEFAULT_UNIQUE_ID",
    totalAmount = 0,
    currencyCode = "",
    isPaused = false,
    isActive = true,
    timer = null,
    actions = [],
    getAsyncTokens,
    streamData,
    gameKind,
    players,
    viewers,
    betters,
    availableSeats = null,
    gameType,
    enabledVideoStreamOrImage = STREAM_SOURCES.LOCAL,
    isGameMuted = false,
    areAllSeatsTaken = false
}) => {
    const { volume, setVolumeChange: handleVolumeChange, toggleMuted: toggleVolumeMute } = useVolume(0);
    const countdown = useCountdown(timer);
    const { nanoPlayerConfig, publicPlayerConfig } = useVideo({ streamData });

    const handleVideoZoom = () => {
        onVideoZoom(tableId);
    };

    const handleOpenDMSView = useCallback(async () => {
        try {
            const { token, refreshToken } = await getAsyncTokens();
            const dmsURL = queryString.stringify(
                {
                    tableId,
                    gameKind,
                    tableName,
                    token,
                    refreshToken,
                    gameType
                },
                { encode: false }
            );
            window.open(`${location.origin}?${dmsURL}`);
        } catch (e) {
            console.error(e);
        }
    }, [tableId, getAsyncTokens, gameKind, gameType, tableName]);

    const containerClasses = classNames(styles.GameContainer, {
        [styles.Selected]: selected,
        [styles.VideoZoomed]: isVideoZoomed
    });

    let mistakes = [];
    let haveActiveMistakes = false;
    let haveInProgressMistakes = false;
    for (const action of actions) {
        const { type, state } = action;
        if (state === ACTION_STATES.PROCESSING) {
            haveInProgressMistakes = true;
            mistakes = [...mistakes, getConstructedMistakeData({ type, state })];
        } else if (action.state === ACTION_STATES.ACTIVE) {
            haveActiveMistakes = true;
            mistakes = [getConstructedMistakeData({ type, state }), ...mistakes];
        }
    }

    const footerClasses = classNames(styles.Footer, {
        [styles.FooterIsZoomInMode]: isVideoZoomed,
        [styles.Shadow]: !isVideoZoomed && (haveActiveMistakes || haveInProgressMistakes)
    });

    const isLocalStreamEnabled = enabledVideoStreamOrImage === STREAM_SOURCES.LOCAL;
    const isNanoCosmosStreamEnabled = enabledVideoStreamOrImage === STREAM_SOURCES.NANO_COSMOS;
    const isStreamImageEnabled = enabledVideoStreamOrImage === STREAM_SOURCES.IMAGES;
    const isGamePaused = !timer && isPaused && isActive;
    const isTableClosed = !timer && !isActive;

    return (
        <div className={containerClasses}>
            <div className={styles.VideoContent}>
                {isLocalStreamEnabled && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <LocalStreamPlayer volume={volume} config={publicPlayerConfig} isPlayerInitialized />
                    </Suspense>
                )}
                {isNanoCosmosStreamEnabled && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <NanoVideoPlayer config={nanoPlayerConfig} volume={volume} videoElementId={htmlId} />
                    </Suspense>
                )}
                {isStreamImageEnabled && (
                    <TablePicture baseUrl={THUMBNAIL_IMAGES_URL} streamImageName={streamData.image} />
                )}

                {timer && (
                    <div className={styles.NotificationContainer}>
                        <div className={styles.StatusText}>
                            <span className={`${styles.DisplayText} ${isActive ? styles.Closing : styles.Opening}`}>{`${
                                isActive ? "CLOSING SOON" : "OPENING SOON"
                            }`}</span>
                            {countdown && <span className={styles.Countdown}>{countdown}</span>}
                        </div>
                    </div>
                )}
                {isTableClosed && (
                    <div className={styles.NotificationContainer}>
                        <div className={styles.StatusText}>
                            <span className={`${styles.DisplayText} ${styles.Closing}`}>TABLE CLOSED</span>
                        </div>
                    </div>
                )}

                {isGamePaused && (
                    <div className={styles.NotificationContainer}>
                        <div className={styles.StatusText}>
                            <span className={`${styles.DisplayText} ${styles.IsPaused}`}>GAME PAUSED</span>
                        </div>
                    </div>
                )}

                {areAllSeatsTaken && (
                    <div className={styles.NotificationContainer}>
                        <span className={`${styles.DisplayText} ${styles.AllSeatsTaken}`}>ALL SEATS ARE TAKEN</span>
                    </div>
                )}
                {isGameMuted && (
                    <div className={styles.MutedInfoContainer}>
                        <GameMutedInfo />
                    </div>
                )}

                <div className={`${styles.HoveredOverlayContent} ${volume > 0 ? styles.SoundIsOn : ""}`}>
                    <GameSettings
                        onZoomIn={handleVideoZoom}
                        onVolumeMuteToggle={toggleVolumeMute}
                        onOpenDms={handleOpenDMSView}
                        isVideoZoomed={isVideoZoomed}
                        volume={volume}
                        onVolumeChange={handleVolumeChange}
                    />
                </div>
            </div>
            <div className={styles.MistakeAndWatch}>
                <Mistakes mistakes={mistakes} />
                {!isVideoZoomed && (
                    <div className={styles.ViewersPlayers}>
                        <ViewersPlayers
                            availableSeats={availableSeats}
                            players={players}
                            betters={betters}
                            viewers={viewers}
                        />
                    </div>
                )}
            </div>
            <div className={footerClasses}>
                <Footer
                    gameName={gameName}
                    dealerNickname={dealerNickname}
                    roundId={roundId}
                    isVideoZoomIn={isVideoZoomed}
                    mistakes={mistakes}
                    flagVersion={flagVersion}
                    languageCode={languageCode}
                    availableSeats={availableSeats}
                    players={players}
                    amount={totalAmount}
                    currencyCode={currencyCode}
                    haveActiveMistakes={haveActiveMistakes}
                    haveInProgressMistakes={haveInProgressMistakes}
                    betters={betters}
                    viewers={viewers}
                />
            </div>
        </div>
    );
};

Game.propTypes = {
    tableId: PropTypes.number.isRequired,
    flagVersion: PropTypes.string,
    roundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    id: PropTypes.number,
    selected: PropTypes.bool,
    onVideoZoom: PropTypes.func,
    isVideoZoomed: PropTypes.bool,
    tableName: PropTypes.string,
    gameName: PropTypes.string,
    dealerNickname: PropTypes.string,
    availableSeats: PropTypes.number,
    players: PropTypes.number.isRequired,
    viewers: PropTypes.number.isRequired,
    betters: PropTypes.number.isRequired,
    htmlId: PropTypes.string,
    isPaused: PropTypes.bool,
    isActive: PropTypes.bool,
    timer: PropTypes.number,
    totalAmount: PropTypes.number,
    currencyCode: PropTypes.string,
    actions: PropTypes.array,
    getAsyncTokens: PropTypes.func.isRequired,
    streamData: PropTypes.object.isRequired,
    gameKind: PropTypes.string.isRequired,
    gameType: PropTypes.string.isRequired,
    enabledVideoStreamOrImage: PropTypes.string,
    isGameMuted: PropTypes.bool,
    areAllSeatsTaken: PropTypes.bool,
    languageCode: PropTypes.string
};

export default memo(Game);
