import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";

import { checkButtonDisabled } from "shared/utils";
import CommentWithReasonFields from "../../CommentWithReasonFields";
import MainModal from "./MainModal";

import styles from "components/Modals/styles.module.scss";

const CancelGame = ({ onClose, onCancelGame, reasons }) => {
    const [form] = Form.useForm();
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);

    const handleOkClick = () => {
        const formValues = form.getFieldsValue();
        const { reasonId, comment } = formValues;
        onCancelGame({ reasonId, comment });
    };

    const onValuesChange = useCallback(allValues => {
        const isConfirmButtonDisabled = checkButtonDisabled(allValues);

        setIsConfirmButtonDisabled(isConfirmButtonDisabled);
    }, []);

    return (
        <MainModal
            onSubmit={handleOkClick}
            onClose={onClose}
            cancelButtonText="No"
            confirmButtonText="Yes"
            title="Cancel Game"
            confirmButtonDisabled={isConfirmButtonDisabled}
        >
            <p>Are you sure you want to cancel this round?</p>

            <div className={styles.PasswordField}>
                <CommentWithReasonFields reasons={reasons} form={form} onValuesChange={onValuesChange} />
            </div>
        </MainModal>
    );
};

CancelGame.propTypes = {
    onClose: PropTypes.func.isRequired,
    onCancelGame: PropTypes.func.isRequired,
    reasons: PropTypes.array.isRequired
};

export default CancelGame;
