import { useEffect } from "react";
import useCustomReducer from "@livecasino/core/hooks/useCustomReducer";
import reducer, { INITIAL_STATE } from "./reducer";
import useSignalR, { SignalRLogLevel } from "@livecasino/core/hooks/useSignalR";
import { PITBOSS_SERVER_URL } from "shared/constants";
import { ERROR_TYPES } from "@livecasino/core/constants/errorTypes";
import setHubConnectionLogger from "@livecasino/core/helpers/setHubConnectionLogger";
import * as types from "./types";
import queryString from "query-string";

const useHeaderData = ({ getAsyncToken, onReceiveInfoMessage, gameType }) => {
    const [state, dispatch] = useCustomReducer(reducer, INITIAL_STATE, true);
    const queryObject = queryString.parse(location.search, { arrayFormat: "comma" });
    const connectionURL = PITBOSS_SERVER_URL + `/gameInfo?tableId=${queryObject.tableId}`;
    const { hubConnection, connectionStatus, areListenersSetRef } = useSignalR({
        url: connectionURL,
        getAsyncToken,
        loggingLevel: SignalRLogLevel.Debug
    });

    useEffect(() => {
        if (areListenersSetRef.current || !hubConnection || !connectionStatus.isConnected) {
            return;
        }
        setHubConnectionLogger(hubConnection, `PitBoss - ${gameType} (Game Info)`);

        hubConnection.on("InitialGameInfoState", data => {
            dispatch({
                type: types.INITIAL_STATE,
                payload: data
            });
        });

        hubConnection.on("RoundIdChanged", data => {
            dispatch({
                type: types.ROUND_ID_CHANGED,
                payload: data
            });
        });

        hubConnection.on("DealerChanged", data => {
            dispatch({
                type: types.DEALER_CHANGED,
                payload: data
            });
        });

        hubConnection.on("TotalBetAmountOrCurrencyChanged", data => {
            dispatch({
                type: types.TOTAL_BETS_CHANGED,
                payload: data
            });
        });

        hubConnection.on("PlayersCountChanged", data => {
            dispatch({
                type: types.PLAYERS_COUNT_CHANGED,
                payload: data
            });
        });

        hubConnection.on("InfoMessage", data => {
            const { message } = data;
            if (typeof onReceiveInfoMessage === "function") {
                onReceiveInfoMessage(message);
            }
        });

        hubConnection.on("GameSoundChanged", data => {
            dispatch({
                type: types.GAME_SOUND_CHANGED,
                payload: data
            });
        });

        hubConnection.on("ManuallyClosed", () => {
            dispatch({ type: types.ERROR, payload: ERROR_TYPES.SESSION_EXPIRED });
        });
        areListenersSetRef.current = true;
    }, [hubConnection, areListenersSetRef, dispatch, onReceiveInfoMessage, connectionStatus.isConnected, gameType]);

    useEffect(() => {
        if (!hubConnection || !connectionStatus.isConnected || !areListenersSetRef.current) {
            return;
        }
        hubConnection.invoke("GetInitialState");
    }, [areListenersSetRef, dispatch, connectionStatus.isConnected, hubConnection]);

    useEffect(() => {
        dispatch({
            type: types.ERROR,
            payload: connectionStatus.error
        });
    }, [connectionStatus.error, dispatch]);

    return {
        ...state
    };
};

export default useHeaderData;
