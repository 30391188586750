import React, { useEffect } from "react";
import { Popover } from "antd";
import PropTypes from "prop-types";
import styles from "./index.module.scss";

function ActionsPopover({ isVisible, togglePopoverCb, children }) {
    useEffect(() => {
        function removePlayerId() {
            togglePopoverCb(null);
        }
        if (isVisible) {
            document.addEventListener("click", removePlayerId);
        }
        return () => {
            document.removeEventListener("click", removePlayerId);
        };
    }, [isVisible, togglePopoverCb]);
    const content = (
        <div className={styles.ActionPopOverContent} onMouseLeave={() => togglePopoverCb(null)}>
            {children}
        </div>
    );
    return (
        <Popover placement="left" content={content} trigger="click" open={isVisible}>
            <span className={styles.PopoverTitle}>action</span>
        </Popover>
    );
}

ActionsPopover.propTypes = {
    togglePopoverCb: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    isVisible: PropTypes.bool.isRequired
};

export default ActionsPopover;
