import { VIDEO_ADAPTION, VIDEO_QUALITY_STREAM_NAMES } from "shared/constants";

function getNanoPlayerConfig(streamEntries) {
    let config = {};
    if (!streamEntries) {
        return config;
    }
    let startIndex = streamEntries.findIndex(stream => stream.label === VIDEO_QUALITY_STREAM_NAMES.MEDIUM);
    if (startIndex === -1) {
        startIndex = 0;
    }

    config = {
        source: {
            entries: streamEntries,
            options: {
                adaption: {
                    rule: VIDEO_ADAPTION.NONE
                },
                switch: {
                    method: "server",
                    pauseOnError: false,
                    forcePlay: true,
                    fastStart: false,
                    timeout: 10
                }
            },
            startIndex
        },
        playback: {
            autoplay: true,
            automute: true,
            muted: true,
            interactive: false,
            flashplayer: "//demo.nanocosmos.de/nanoplayer/nano.player.swf"
        },
        style: {
            width: "100%",
            height: "100%",
            controls: false,
            // view: false,
            backgroundColor: "none",
            displayMutedAutoplay: false
        },
        events: {}
    };
    return config;
}
export default getNanoPlayerConfig;
