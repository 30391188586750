import React from "react";
import PropTypes from "prop-types";
import styles from "../index.module.scss";

const DMSFrame = ({ iframeURL }) => {
    return (
        <div className={styles.StreamWrapper}>
            <div className={styles.Iframe}>
                <iframe src={iframeURL} scrolling="no" />
                <div className={styles.Layer} />
            </div>
        </div>
    );
};

DMSFrame.propTypes = {
    iframeURL: PropTypes.string.isRequired
};

DMSFrame.defaultProps = {};
export default DMSFrame;
