import React, { useCallback } from "react";
import { USER_ACTION_TYPES } from "shared/constants";
import Popover from "../Popover";
import PropTypes from "prop-types";

function ClassicBlackjackActionsPopover({ isUser, player, boxId, onItemClick, selectedPlayerId, togglePopoverCb }) {
    const boxIdToRemove = isUser ? null : boxId;
    const removeSeatText = isUser ? "Remove From All Seats" : "Remove From Seat";

    const isVisible = isUser
        ? selectedPlayerId === player.playerId
        : selectedPlayerId === String(player.playerId) + boxId;

    const handleRemoveFromSeatClick = useCallback(
        e => {
            e.stopPropagation();
            onItemClick({ type: USER_ACTION_TYPES.REMOVE, data: { player, boxIdToRemove } });
        },
        [onItemClick, player, boxIdToRemove]
    );

    const content = (
        <>
            <button onClick={() => onItemClick({ type: USER_ACTION_TYPES.VIEW, data: { player } })}>
                Player Profile
            </button>
            <button onClick={handleRemoveFromSeatClick}>{removeSeatText}</button>
            <button onClick={() => onItemClick({ type: USER_ACTION_TYPES.BLOCK, data: { player } })}>Block User</button>
        </>
    );
    return (
        <Popover isVisible={isVisible} togglePopoverCb={togglePopoverCb}>
            {content}
        </Popover>
    );
}

ClassicBlackjackActionsPopover.propTypes = {
    isUser: PropTypes.bool.isRequired,
    player: PropTypes.object.isRequired,
    boxId: PropTypes.PropTypes.number,
    onItemClick: PropTypes.func,
    selectedPlayerId: PropTypes.PropTypes.number,
    togglePopoverCb: PropTypes.func
};

ClassicBlackjackActionsPopover.defaultProps = {
    onItemClick: null,
    togglePopoverCb: null,
    selectedPlayerId: null,
    boxId: null
};
export default ClassicBlackjackActionsPopover;
