import { addLastRoundToList } from "../../../shared/utils";

export function initialState({ payload, state }) {
    const { lastRounds } = payload;
    return { ...state, lastRounds, loading: false };
}

export function roundAdded({ payload, state }) {
    const updatedLastRounds = addLastRoundToList(state.lastRounds, payload);
    return { ...state, lastRounds: updatedLastRounds };
}
