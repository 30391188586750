import React from "react";
import RadioButtonWithLabel from "@livecasino/core/v2/components/RadioButtonWithLabel";
import PropTypes from "prop-types";
import styles from "./index.module.scss";
import { STREAM_SOURCES } from "../../shared/constants";

const StreamSwitchRadioButtons = ({ selectedSource, onsSourceChange }) => {
    return (
        <div className={styles.Container}>
            <div className={styles.RadioButton}>
                <RadioButtonWithLabel
                    label={STREAM_SOURCES.LOCAL}
                    checked={selectedSource === STREAM_SOURCES.LOCAL}
                    onClick={() => onsSourceChange(STREAM_SOURCES.LOCAL)}
                />
            </div>
            <div className={styles.RadioButton}>
                <RadioButtonWithLabel
                    label={STREAM_SOURCES.NANO_COSMOS}
                    checked={selectedSource === STREAM_SOURCES.NANO_COSMOS}
                    onClick={() => onsSourceChange(STREAM_SOURCES.NANO_COSMOS)}
                />
            </div>
            <div className={styles.RadioButton}>
                <RadioButtonWithLabel
                    label={STREAM_SOURCES.IMAGES}
                    checked={selectedSource === STREAM_SOURCES.IMAGES}
                    onClick={() => onsSourceChange(STREAM_SOURCES.IMAGES)}
                />
            </div>
        </div>
    );
};

StreamSwitchRadioButtons.propTypes = {
    selectedSource: PropTypes.string.isRequired,
    onsSourceChange: PropTypes.func.isRequired
};

export default StreamSwitchRadioButtons;
