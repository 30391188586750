import React from "react";
import PropTypes from "prop-types";
import MainModal from "./MainModal";

const RescanAll = ({ onClose, onSubmit }) => {
    const handleOkClick = () => {
        onSubmit(true);
    };

    return (
        <MainModal
            onSubmit={handleOkClick}
            onClose={onClose}
            cancelButtonText="No"
            confirmButtonText="Yes"
            title="Rescan All"
        >
            <p>Are you sure you want to rescan all cards?</p>
        </MainModal>
    );
};

RescanAll.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default RescanAll;
