import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";

import MainModal from "./MainModal";
import CommentWithReasonFields from "../../CommentWithReasonFields";

import { checkButtonDisabled } from "../../../shared/utils";

const DMSMistakeInfoModal = ({ onSubmit, onClose, reasons, title }) => {
    const [generateButtonDisabled, setGenerateButtonDisabled] = useState(true);

    const [form] = Form.useForm();

    const handleSubmit = useCallback(() => {
        onSubmit({ ...form.getFieldsValue(), type: title });
        onClose();
    }, [onSubmit, form, title, onClose]);

    const handleFormValuesChange = allValues => {
        setGenerateButtonDisabled(checkButtonDisabled(allValues));
    };

    return (
        <MainModal
            onSubmit={handleSubmit}
            confirmButtonText="Yes"
            cancelButtonText="Cancel"
            onClose={onClose}
            title={title}
            confirmButtonDisabled={generateButtonDisabled}
        >
            <p>To confirm, please fill in the required fields and click Yes</p>
            <CommentWithReasonFields showSearch form={form} onValuesChange={handleFormValuesChange} reasons={reasons} />
        </MainModal>
    );
};

DMSMistakeInfoModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    reasons: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
};

export default DMSMistakeInfoModal;
