import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss";
import ReactPlayer from "react-player/file";

const config = {
    hlsVersion: "1.2.9"
};
function RecordedStreamPage({ recordedStreamUrl, tableName }) {
    const videoRef = useRef(null);
    useEffect(() => {
        const videoEl = videoRef.current.getInternalPlayer();
        videoEl.setAttribute("disablePictureInPicture", true);
    }, []);

    return (
        <div className={styles.PitBossNewWindow}>
            <div className={styles.RecordedStreamHeader}>
                <span>Table name:</span>
                <span className={styles.HighLightedText}>{tableName}</span>
            </div>
            <div className={styles.VideoWrapper}>
                <ReactPlayer
                    ref={videoRef}
                    width="100%"
                    height="100%"
                    config={config}
                    url={recordedStreamUrl}
                    controls
                />
            </div>
        </div>
    );
}

RecordedStreamPage.propTypes = {
    recordedStreamUrl: PropTypes.string.isRequired,
    tableName: PropTypes.string.isRequired
};

export default RecordedStreamPage;
