import * as types from "./types";

export const INITIAL_STATE = {
    roundId: "",
    startTime: null,
    baseBet: 0,
    sideBet: 0,
    insurance: 0,
    double: 0,
    split: 0,
    dealerNickname: "",
    isGameMuted: false,
    players: null,
    totalBets: 0,
    isGamePaused: false,
    error: null,
    tableName: "",
    streamData: null
};

export default function reducer(state, action) {
    switch (action.type) {
        case types.INITIAL_STATE: {
            const {
                baseBet,
                dealerNickname,
                double,
                roundId,
                insurance,
                isGameMuted,
                players,
                betters,
                viewers,
                sideBet,
                split,
                startTime,
                isGamePaused,
                totalBets,
                tableName,
                streamData
            } = action.payload;

            return {
                baseBet,
                dealerNickname,
                double,
                roundId,
                insurance,
                isGameMuted,
                players,
                betters,
                viewers,
                sideBet,
                split,
                startTime,
                totalBets,
                isGamePaused,
                tableName,
                streamData
            };
        }

        case types.DATA_CHANGED: {
            return { ...state, ...action.payload };
        }

        case types.PLAYERS_COUNT_CHANGED: {
            const { players, betters, viewers } = action.payload;
            return { ...state, players, betters, viewers };
        }

        case types.TABLE_STATUS_CHANGED: {
            const {
                status: { isPaused: isGamePaused }
            } = action.payload;
            return { ...state, isGamePaused };
        }

        case types.ROUND_ID_CHANGED: {
            const { roundId, gameStartTime: startTime } = action.payload;
            return {
                ...state,
                roundId,
                startTime,
                baseBet: 0,
                sideBet: 0,
                insurance: 0,
                double: 0,
                split: 0,
                totalBets: 0
            };
        }

        case types.DEALER_CHANGED: {
            const { dealerNickname } = action.payload;
            return { ...state, dealerNickname };
        }
        case types.GAME_SOUND_CHANGED: {
            const { isMuted: isGameMuted } = action.payload;
            return { ...state, isGameMuted };
        }
        case types.ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
    }
}
