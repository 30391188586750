import { lazy } from "react";
import { GAME_KINDS } from "shared/constants";
import { GAME_TYPES } from "@livecasino/core/constants/gameTypes";

const AutoRouletteNavigationTabsLazy = lazy(() => import("components/NavigationTabs/AutoRoulette"));
const PiratePlunderNavigationTabsLazy = lazy(() => import("components/NavigationTabs/PiratePlunder"));
const SicboNavigationTabsLazy = lazy(() => import("components/NavigationTabs/Sicbo"));
const ClassicBaccaratNavigationTabsLazy = lazy(() => import("components/NavigationTabs/ClassicBaccarat"));
const DragonTigerNavigationTabsLazy = lazy(() => import("components/NavigationTabs/DragonTiger"));
const HiLoNavigationTabsLazy = lazy(() => import("components/NavigationTabs/HiLo"));
const EuropeanRouletteNavigationTabsLazy = lazy(() => import("components/NavigationTabs/EuropeanRoulette"));
const AndarBaharNavigationTabsLazy = lazy(() => import("components/NavigationTabs/AndarBahar"));
const CasinoHoldemNavigationTabsLazy = lazy(() => import("components/NavigationTabs/CasinoHoldem"));
const RobinTheFairNavigationTabsLazy = lazy(() => import("components/NavigationTabs/RobinTheFair"));
const TeenPattiNavigationTabsLazy = lazy(() => import("components/NavigationTabs/TeenPatti"));

const tabState = {
    tabsContentComponent: null,
    gameKind: null
};

export const getNavigationTabComponent = gameType => {
    switch (gameType) {
        case GAME_TYPES.AUTO_ROULETTE:
        case GAME_TYPES.MULTIPLIER_AUTO_ROULETTE:
            tabState.tabsContentComponent = AutoRouletteNavigationTabsLazy;
            tabState.gameKind = GAME_KINDS.ROULETTE;
            break;
        case GAME_TYPES.EUROPEAN_ROULETTE:
        case GAME_TYPES.AMERICAN_ROULETTE:
        case GAME_TYPES.MULTIPLIER_ROULETTE:
            tabState.tabsContentComponent = EuropeanRouletteNavigationTabsLazy;
            tabState.gameKind = GAME_KINDS.ROULETTE;
            break;
        case GAME_TYPES.PIRATE_PLUNDER:
            tabState.tabsContentComponent = PiratePlunderNavigationTabsLazy;
            tabState.gameKind = GAME_KINDS.PIRATE_PLUNDER;
            break;
        case GAME_TYPES.SICBO:
            tabState.tabsContentComponent = SicboNavigationTabsLazy;
            tabState.gameKind = GAME_KINDS.SICBO;
            break;
        case GAME_TYPES.SPEED_BACCARAT:
            tabState.tabsContentComponent = ClassicBaccaratNavigationTabsLazy;
            tabState.gameKind = GAME_KINDS.BACCARAT;
            break;
        case GAME_TYPES.SPEED_DRAGON_TIGER:
            tabState.tabsContentComponent = DragonTigerNavigationTabsLazy;
            tabState.gameKind = GAME_KINDS.DRAGON_TIGER;
            break;
        case GAME_TYPES.ANDAR_BAHAR:
            tabState.tabsContentComponent = AndarBaharNavigationTabsLazy;
            tabState.gameKind = GAME_KINDS.ANDAR_BAHAR;
            break;
        case GAME_TYPES.CASINO_HOLDEM:
            tabState.tabsContentComponent = CasinoHoldemNavigationTabsLazy;
            tabState.gameKind = GAME_KINDS.CASINO_HOLDEM;
            break;
        case GAME_TYPES.ROBIN_THE_FAIR:
        case GAME_TYPES.ROBIN_THE_FAIR_2:
            tabState.tabsContentComponent = RobinTheFairNavigationTabsLazy;
            tabState.gameKind = GAME_KINDS.ROBIN_THE_FAIR;
            break;
        case GAME_TYPES.TEEN_PATTI:
            tabState.tabsContentComponent = TeenPattiNavigationTabsLazy;
            tabState.gameKind = GAME_KINDS.TEEN_PATTI;
            break;
        case GAME_TYPES.HI_LO_FOOTBALL:
            tabState.tabsContentComponent = HiLoNavigationTabsLazy;
            tabState.gameKind = GAME_KINDS.HI_LO_FOOTBALL;
            break;
        default:
            break;
    }

    return tabState;
};
