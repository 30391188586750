export function renderShufflerSelectOptions(list, value = "id") {
    if (!list) {
        return [];
    }

    return list.map(item => {
        let label = item.name;

        if (item.surname) {
            label += ` ${item.surname}`;
        }
        return {
            label,
            value: item[value]
        };
    });
}
