import React, { useMemo } from "react";
import ControlButton from "@livecasino/core/v2/Backoffice_DMS_Pitboss/components/ControlButton";
import PropTypes from "prop-types";
import { Table } from "antd";
import { cardRender, momentFormatter } from "shared/utils";
import { ACTIONS, CARD_TYPES, DATE_FORMAT, INITIAL_TABLE_CONFIG, MODAL_TYPES } from "shared/constants";
import { antdTableColumnsListCreator } from "@livecasino/core/helpers/metadataCreator";
import styles from "./index.module.scss";

const SCROLL_DATA = { y: "calc(100% - 39px)" };

const RescanCard = ({ tableColumns, rescanDetails, loading, onRescanButtonsClick = null, buttonsPending }) => {
    const { isRescanDisabled } = buttonsPending;
    const tableConfig = useMemo(
        () => ({
            ...INITIAL_TABLE_CONFIG,
            actions: {
                dateFormatter: function handleDateFormatter(data) {
                    return momentFormatter(data.text, DATE_FORMAT);
                },
                cardRender: cardRender
            },
            customRenders: {
                boxId: function boxRenderer(data) {
                    if (data !== CARD_TYPES.DEALER) {
                        return <div>{Number(data) + 1}</div>;
                    }
                    return <div>{data}</div>;
                }
            },
            breakPoints: {
                Card: 50,
                Box: 50,
                Time: 100,
                handType: 100
            }
        }),
        []
    );

    const mappedColumns = antdTableColumnsListCreator(tableColumns, tableConfig);

    return (
        <div className={styles.Container}>
            <div className={styles.TableWrapper}>
                <Table
                    dataSource={rescanDetails}
                    columns={mappedColumns}
                    pagination={false}
                    loading={loading}
                    size="small"
                    scroll={SCROLL_DATA}
                    className="height100"
                    rowKey={record => record.time}
                />
            </div>
            <div className={styles.RescanButtons}>
                <div className={styles.ActionButton}>
                    <ControlButton
                        fontSize="0.9em"
                        textColor="var(--lc-pitboss__red_500)"
                        onClick={() => onRescanButtonsClick(MODAL_TYPES.RESCAN_ALL)}
                        disabled={isRescanDisabled}
                    >
                        {ACTIONS.RESCAN_ALL}
                    </ControlButton>
                </div>

                <div className={styles.ActionButton}>
                    <ControlButton
                        fontSize="0.9em"
                        disabled={isRescanDisabled}
                        onClick={() => onRescanButtonsClick(MODAL_TYPES.RESCAN_LAST)}
                    >
                        {ACTIONS.RESCAN_LAST}
                    </ControlButton>
                </div>
            </div>
        </div>
    );
};
RescanCard.propTypes = {
    rescanDetails: PropTypes.array.isRequired,
    tableColumns: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    onRescanButtonsClick: PropTypes.func,
    buttonsPending: PropTypes.object.isRequired
};

export default RescanCard;
