import React from "react";
import styles from "./index.module.scss";
import Sound from "@livecasino/core/v2/components/Sound";
import Slider from "@livecasino/core/v2/components/Slider";
import PropTypes from "prop-types";
import Tooltip from "@livecasino/core/v2/components/Tooltip";
import { SCALABLE_SLIDER_STYLES } from "shared/constants";

const SoundSlider = ({ onVolumeMuteToggle, onVolumeChange, volume }) => {
    const soundToolTipContent = (
        <div className={styles.SliderWrapper}>
            <Slider
                sliderSize="2px"
                value={volume}
                min={0}
                max={1}
                step={0.1}
                onChange={onVolumeChange}
                toolTipColor="var(--lc-pitboss__brown_500)"
                {...SCALABLE_SLIDER_STYLES}
            />
        </div>
    );

    return (
        <div className={styles.SoundControls}>
            <Tooltip
                position="right"
                content={soundToolTipContent}
                fontSize={16}
                arrow={false}
                interactive
                hideOnClick={false}
            >
                <div className={styles.SoundButton} onClick={onVolumeMuteToggle}>
                    <Sound color="var(--lc-pitboss__black_900)" soundOn={Boolean(volume)} />
                </div>
            </Tooltip>
        </div>
    );
};
SoundSlider.propTypes = {
    onVolumeMuteToggle: PropTypes.func,
    volume: PropTypes.number.isRequired,
    onVolumeChange: PropTypes.func
};
SoundSlider.defaultProps = {
    onVolumeMuteToggle: Function.prototype,
    onVolumeChange: Function.prototype
};

export default SoundSlider;
