import { useRef, useEffect } from "react";
import useCustomReducer from "@livecasino/core/hooks/useCustomReducer";
import reducer, { INITIAL_STATE } from "./reducer";
import useSignalR from "@livecasino/core/hooks/useSignalR";
import { PITBOSS_SERVER_URL } from "shared/constants";
import * as types from "./types";
import setHubConnectionLogger from "@livecasino/core/helpers/setHubConnectionLogger";
import queryString from "query-string";

const useLastRoundsTabData = ({ getAsyncToken, gameKind }) => {
    const [state, dispatch] = useCustomReducer(reducer, INITIAL_STATE, true);
    const isListenersSet = useRef(false); // TODO: ask and remove
    const queryObject = queryString.parse(location.search, { arrayFormat: "comma" });
    const connectionURL = PITBOSS_SERVER_URL + `/lastRounds?tableid=${queryObject.tableId}`;
    const { hubConnection, connectionStatus, areListenersSetRef } = useSignalR({
        url: connectionURL,
        getAsyncToken
    });

    useEffect(() => {
        if (areListenersSetRef.current || !hubConnection || !connectionStatus.isConnected) {
            return;
        }
        setHubConnectionLogger(hubConnection, `PitBoss - ${gameKind} (Tab:Last Rounds)`);

        hubConnection.on("InitialLastRoundsState", data => {
            dispatch({
                type: types.INITIAL_STATE,
                payload: data
            });
        });

        hubConnection.on("AddNewEndedRound", data => {
            dispatch({
                type: types.ROUND_ADDED,
                payload: data
            });
        });
        areListenersSetRef.current = true;
    }, [hubConnection, connectionStatus.isConnected, isListenersSet, dispatch, areListenersSetRef, gameKind]);

    useEffect(() => {
        if (!hubConnection || !connectionStatus.isConnected || !areListenersSetRef.current) {
            return;
        }
        hubConnection.invoke("GetInitialState").catch(console.error);
    }, [areListenersSetRef, dispatch, connectionStatus.isConnected, hubConnection]);

    return {
        ...state
    };
};

export default useLastRoundsTabData;
