import React from "react";
import styles from "./Footer.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import FlagIcon from "@livecasino/core/v2/components/Languages/Language/FlagIcon";
import BalanceIcon from "@livecasino/core/v2/Backoffice_DMS_Pitboss/svgs/Balance";
import ViewersPlayers from "@livecasino/core/v2/components/ViewersPlayersBetters";
import { FLAGS_URL } from "../../shared/constants";

const Footer = ({
    gameName,
    roundId,
    dealerNickname = "",
    isVideoZoomIn = false,
    flagVersion = null,
    languageCode = null,
    availableSeats = null,
    players,
    betters,
    viewers,
    amount = 0,
    currencyCode = "",
    haveActiveMistakes = false,
    haveInProgressMistakes = false
}) => {
    const footerClasses = classNames(styles.Footer, styles.NoMistake, {
        [styles.FooterIsZoomInMode]: isVideoZoomIn,
        [styles.Mistake]: haveActiveMistakes,
        [styles.InProgress]: !haveActiveMistakes && haveInProgressMistakes
    });
    const flagUrl = flagVersion && languageCode ? `${FLAGS_URL}/${flagVersion}/${languageCode}.jpg` : null;

    return (
        <div className={footerClasses}>
            <div className={styles.NameIdLanguageDealer}>
                <div className={styles.LeftSide}>
                    <div className={styles.FlagAndGameNameWrapper}>
                        {flagUrl && (
                            <div className={styles.GameInfoFlag}>
                                <div className={styles.IconWrapper}>
                                    <FlagIcon iconUrl={flagUrl} isRounded />
                                </div>
                            </div>
                        )}
                        <p className={styles.GameName}>{gameName}</p>
                    </div>
                    <p className={styles.RoundId}>ID: {roundId}</p>
                </div>
                <div className={styles.RightSide}>
                    <div className={styles.AmountContainer}>
                        <div className={styles.BalanceIconWrapper}>
                            <BalanceIcon color="var(--lc-pitboss__yellow_800, rgb(255, 183, 0))" />
                        </div>
                        <p>
                            {amount} {currencyCode}
                        </p>
                    </div>
                    <p className={styles.DealerName}>{dealerNickname ? `Dealer: ${dealerNickname}` : "No Dealer"}</p>
                </div>
            </div>
            {isVideoZoomIn && (
                <div className={styles.PlayersAndWatchers}>
                    <ViewersPlayers
                        availableSeats={availableSeats}
                        players={players}
                        betters={betters}
                        viewers={viewers}
                    />
                </div>
            )}
        </div>
    );
};

Footer.propTypes = {
    availableSeats: PropTypes.number,
    players: PropTypes.number.isRequired,
    viewers: PropTypes.number.isRequired,
    betters: PropTypes.number.isRequired,
    gameName: PropTypes.string.isRequired,
    roundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    dealerNickname: PropTypes.string,
    isVideoZoomIn: PropTypes.bool,
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
    haveActiveMistakes: PropTypes.bool,
    haveInProgressMistakes: PropTypes.bool,
    flagVersion: PropTypes.string,
    languageCode: PropTypes.string
};

export default Footer;
