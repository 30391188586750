import React, { useCallback, useState, useMemo } from "react";
import PitBossChatModule from "@livecasino/core/v2/Backoffice_DMS_Pitboss/components/Chat/PitBossChat/PitBossChatModule";
import Modal from "@livecasino/core/v2/components/Modal";
import BlockUser from "components/Modals/ModalContent/BlockUser";
import PropTypes from "prop-types";
import { CHAT_URL } from "shared/constants";
import styles from "./index.module.scss";
import { showNotification, onCloseHandler } from "@livecasino/core/v2/components/Notification";
import InfoMessage from "@livecasino/core/v2/components/InfoMessage";
import { getBlockUserDurations, openPlayerReportInNewTab } from "../../../../shared/utils";

const ChatTab = ({ tableId, getAsyncToken }) => {
    const [selectedMessageId, setSelectedMessageId] = useState(null);
    const [userStatusUpdateOptions, setUserStatusUpdateOptions] = useState({
        blockReasons: [],
        blockDurations: []
    });
    const pitBossChatRef = React.useRef(null);

    const blockDurations = useMemo(
        () => getBlockUserDurations(userStatusUpdateOptions.blockDurations),
        [userStatusUpdateOptions.blockDurations]
    );

    const onReceiveInfoMessage = useCallback(message => {
        showNotification(<InfoMessage message={message} transparent={false} onClose={onCloseHandler} />);
    }, []);

    const onBlock = useCallback(data => {
        const { message } = data;
        setSelectedMessageId(message.id);
    }, []);

    const handleCloseModal = useCallback(() => {
        setSelectedMessageId(null);
    }, []);

    const handlePlayerProfileClick = useCallback(messageObject => {
        openPlayerReportInNewTab(messageObject.senderId);
    }, []);

    const handleSubmit = useCallback(
        values => {
            const { note, playerChatBlockType, blockDurationSeconds } = values;
            handleCloseModal();
            const userStatusUpdateModel = {
                note,
                playerChatBlockType,
                blockDurationSeconds,
                messageId: selectedMessageId
            };
            pitBossChatRef.current?.block(userStatusUpdateModel);
        },
        [selectedMessageId, handleCloseModal]
    );

    return (
        <div className={styles.ChatContainer}>
            <PitBossChatModule
                chatRef={pitBossChatRef}
                onBlock={onBlock}
                getAsyncToken={getAsyncToken}
                chatServerURL={CHAT_URL + `?tableId=${tableId}`}
                initialStateCount={50}
                onOptionsChange={setUserStatusUpdateOptions}
                onInfoMessageCB={onReceiveInfoMessage}
                onOpenPlayerProfile={handlePlayerProfileClick}
            />
            <Modal open={Boolean(selectedMessageId)}>
                <BlockUser
                    onClose={handleCloseModal}
                    onSubmit={handleSubmit}
                    blockReasons={userStatusUpdateOptions.blockReasons}
                    blockDurations={blockDurations}
                />
            </Modal>
        </div>
    );
};

ChatTab.propTypes = {
    tableId: PropTypes.string.isRequired,
    getAsyncToken: PropTypes.func.isRequired
};

export default ChatTab;
