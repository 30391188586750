import React from "react";
import { Typography, Tooltip } from "antd";
import moment from "moment";
import SingleCard from "@livecasino/core/v2/components/Cards/SingleCard";
import queryString from "query-string";
import { ACTION_DISPLAY_NAMES_BY_TYPE, BACKOFFICE_URL, MISTAKE_TAGS_STATES_COLORS } from "./constants";
import CreateRow from "../components/NavigationTabs/shared/CreateRow";

export const toolTipTitleRender = title => {
    return (
        <Typography.Text copyable strong className={"toolTipTitle"}>
            {title}
        </Typography.Text>
    );
};

export const getLabel = label => <span className="FormItemLabel">{label}</span>;

export const required = field => ({
    required: true,
    message: `${field} is required!`
});

export function toolTipRender(currentRender, data, ...args) {
    return (
        <Tooltip
            placement="topLeft"
            title={() => toolTipTitleRender(data)}
            color={"var(--lc-pitboss__white_900, rgb(255, 255, 255))"}
        >
            {currentRender(data, ...args)}
        </Tooltip>
    );
}

export function getExpandedColumn(rows) {
    return <CreateRow rows={rows} />;
}

export function getConstructedMistakeData({ type, state }) {
    const { bgColor, color } = MISTAKE_TAGS_STATES_COLORS[state];
    const text = ACTION_DISPLAY_NAMES_BY_TYPE[type];

    return {
        text,
        state,
        bgColor,
        color
    };
}

export function checkButtonDisabled(allValues) {
    return !allValues.comment || !allValues.reasonId;
}

export function isValidDiceNumber(diceValue) {
    return Boolean(diceValue && diceValue >= 1 && diceValue <= 6);
}

export function checkDicesValidNumber(dicesValues) {
    for (const value in dicesValues) {
        if (dicesValues.hasOwnProperty(value)) {
            if (!isValidDiceNumber(dicesValues[value])) {
                return false;
            }
        }
    }
    return true;
}

export function renderSelectOptions(list, valueField = "value", value = "id") {
    if (!list) {
        return [];
    }

    return list.map(item => {
        return {
            label: item[valueField],
            value: item[value]
        };
    });
}

export function renderSelectDealerOptions(list) {
    if (!list) {
        return [];
    }

    return list.map(item => {
        const { barcode, nickname, name, surname } = item;
        return {
            label: `${nickname} (${name} ${surname})`,
            value: barcode
        };
    });
}

export const momentFormatter = (date, formatter = "") => {
    if (!date) {
        return null;
    }
    const momentObject = moment.isMoment(date) ? date : moment(date);
    return momentObject.format(formatter);
};

export function cardRender(data) {
    const { card } = data.record;

    if (!card) {
        return null;
    }
    if (card && !card.suit && !card.face) {
        return (
            <div className="cardItem">
                <SingleCard backface />
            </div>
        );
    }
    return (
        <div className="cardItem">
            <SingleCard face={card?.face?.toLowerCase()} suit={card?.suit?.toLowerCase()} />
        </div>
    );
}

export const handleOpenTableHistory = gameKind => {
    const redirectURL = `${BACKOFFICE_URL}/reports/game-reports?selectedTab=${gameKind}`;
    window.open(redirectURL);
};

/**
 *
 * @param milliseconds Array<number>
 * @returns Array <{id: number, value:string}>
 */

export function getBlockUserDurations(milliseconds) {
    if (!milliseconds) {
        return [];
    }
    return milliseconds.map(seconds => {
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        if (hours < 24) {
            return {
                id: seconds,
                value: `${hours} hours`
            };
        }
        return {
            id: seconds,
            value: `${hours / 24} days`
        };
    });
}

export function isEmptyStringOrNumberBetween0and36(value) {
    if (typeof value !== "string") {
        throw new Error("value should be a string");
    }
    const isValueGreaterThen0 = Number(value) >= 0;
    const isValueLessThen36 = Number(value) <= 36;

    if (value === "") {
        return true;
    }

    return isValueGreaterThen0 && isValueLessThen36;
}

export function openPlayerReportInNewTab(playerId) {
    const queryObj = {
        reportStartDate: moment() //reportStartDate end reportEndDate keys are used from pitboss DO NOT CHANGE
            .subtract(7, "d")
            .format(),
        reportEndDate: moment().format()
    };
    const query = queryString.stringify(queryObj);
    const redirectURL = `${BACKOFFICE_URL}/reports/player-reports/player-profile/${playerId}?${query}`;
    window.open(redirectURL);
}

export const addLastRoundToList = (lastRounds, round, roundsCount = 10) => {
    const updatedLastRounds = [round, ...lastRounds];

    return updatedLastRounds.slice(0, roundsCount);
};

export const filterSelectOption = (input, option) => {
    if (input === "") {
        return true;
    }
    return option.label.toLowerCase().includes(input.toLowerCase());
};

/**
 *
 * @param reasons (object)
 * @returns Array <{id: number, value: string}>
 */

export function getBlockUserReasons(reasons) {
    if (!reasons) {
        return [];
    }
    const reasonsList = [];
    for (const key in reasons) {
        if (reasons.hasOwnProperty(key)) {
            reasonsList.push({ id: key, value: reasons[key] });
        }
    }
    return reasonsList;
}
