import { useEffect, useCallback } from "react";
import useCustomReducer from "@livecasino/core/hooks/useCustomReducer";
import reducer, { INITIAL_STATE } from "hooks/ClassicBlackjackHooks/usePlayersTabData/reducer";
import useSignalR from "@livecasino/core/hooks/useSignalR";
import { PITBOSS_SERVER_URL } from "shared/constants";
import setHubConnectionLogger from "@livecasino/core/helpers/setHubConnectionLogger";
import * as types from "hooks/ClassicBlackjackHooks/usePlayersTabData/types";
import queryString from "query-string";

const usePlayersTabData = ({ getAsyncToken, onReceiveInfoMessage }) => {
    const [state, dispatch] = useCustomReducer(reducer, INITIAL_STATE, true);
    const queryObject = queryString.parse(location.search, { arrayFormat: "comma" });
    const connectionURL = PITBOSS_SERVER_URL + `/activePlayers?tableId=${queryObject.tableId}`;
    const { hubConnection, connectionStatus, areListenersSetRef } = useSignalR({
        url: connectionURL,
        getAsyncToken
    });

    const handleOpenModal = useCallback(
        type => {
            dispatch({
                type: types.OPEN_MODAL,
                payload: type
            });
        },
        [dispatch]
    );

    const handleRemoveFromSeat = useCallback(
        (playerId, boxId) => {
            hubConnection.invoke("RemoveFromSeat", playerId, boxId);
        },
        [hubConnection]
    );

    const handleCloseModal = useCallback(() => {
        dispatch({
            type: types.CLOSE_MODAL
        });
    }, [dispatch]);

    const handleBlockUser = useCallback(
        (values, playerId) => {
            const {
                blockDurationSeconds: blockDuration,
                note: notes,
                playerChatBlockType: statusChangeReason
            } = values;
            const blockPlayerModel = { blockDuration, notes, statusChangeReason, playerId };
            hubConnection.invoke("BlockUser", blockPlayerModel);

            handleCloseModal();
        },
        [hubConnection, handleCloseModal]
    );

    useEffect(() => {
        if (areListenersSetRef.current || !hubConnection || !connectionStatus.isConnected) {
            return;
        }
        setHubConnectionLogger(hubConnection, "PitBoss  - BlackJack (Tab:Active Players)");
        hubConnection.on("InitialPlayersState", data => {
            dispatch({
                type: types.INITIAL_STATE,
                payload: data
            });
        });

        hubConnection.on("PlayerSeatOnbox", data => {
            dispatch({
                type: types.PLAYER_SEAT_ON_BOX,
                payload: data
            });
        });

        hubConnection.on("PlayerLeaveBox", data => {
            dispatch({
                type: types.PLAYER_LEAVE_BOX,
                payload: data
            });
        });
        hubConnection.on("NewRoundStarted", data => {
            dispatch({
                type: types.NEW_ROUND_STARTED,
                payload: data
            });
        });

        hubConnection.on("MainBet", data => {
            dispatch({
                type: types.MAIN_BET,
                payload: data
            });
        });

        hubConnection.on("SideBet", data => {
            dispatch({
                type: types.SIDE_BET,
                payload: data
            });
        });

        hubConnection.on("SideBetWin", data => {
            dispatch({
                type: types.SIDE_BET_WIN,
                payload: data
            });
        });

        hubConnection.on("Double", data => {
            dispatch({
                type: types.DOUBLE,
                payload: data
            });
        });

        hubConnection.on("Insurance", data => {
            dispatch({
                type: types.INSURANCE,
                payload: data
            });
        });

        hubConnection.on("Split", data => {
            dispatch({
                type: types.SPLIT,
                payload: data
            });
        });

        hubConnection.on("InfoMessage", data => {
            const { message } = data;
            if (typeof onReceiveInfoMessage === "function") {
                onReceiveInfoMessage(message);
            }
        });
        areListenersSetRef.current = true;
    }, [hubConnection, connectionStatus.isConnected, dispatch, onReceiveInfoMessage, areListenersSetRef]);

    useEffect(() => {
        if (!hubConnection || !connectionStatus.isConnected || !areListenersSetRef.current) {
            return;
        }
        hubConnection.invoke("GetInitialState").catch(console.error);
    }, [areListenersSetRef, dispatch, connectionStatus.isConnected, hubConnection]);

    return {
        ...state,
        handleRemoveFromSeat,
        handleBlockUser,
        handleOpenModal,
        handleCloseModal
    };
};

export default usePlayersTabData;
