import React, { useCallback } from "react";
import PropTypes from "prop-types";
import MainModal from "./MainModal";

const MuteForUsers = ({ onClose, gameConfig, onSubmit }) => {
    const { isGameMuted } = gameConfig;
    const handleOkClick = useCallback(() => {
        const muted = !isGameMuted;
        onSubmit(muted);
    }, [isGameMuted, onSubmit]);

    let title = isGameMuted ? "Unmute Game" : "Mute Game";
    let message = isGameMuted ? "If you want to unmute game for all users?" : "If you want to mute game for all users?";

    return (
        <MainModal
            onSubmit={handleOkClick}
            onClose={onClose}
            cancelButtonText="No"
            confirmButtonText="Yes"
            title={title}
        >
            {message}
        </MainModal>
    );
};

MuteForUsers.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    gameConfig: PropTypes.shape({
        isGameMuted: PropTypes.bool,
        isGamePaused: PropTypes.bool,
        isGameActive: PropTypes.bool
    }).isRequired
};

export default MuteForUsers;
