import React from "react";
import PropTypes from "prop-types";

import { Input, Form, Select } from "antd";

import { filterSelectOption, getLabel, renderSelectOptions, required } from "../../shared/utils";

import styles from "./index.module.scss";

const REQUIRED_FIELDS_RULES = [required("This field")];

const CommentWithReasonFields = ({ onValuesChange, form, reasons = [], showSearch = false }) => {
    return (
        <Form form={form} layout="vertical" onValuesChange={(_, allValues) => onValuesChange(allValues)}>
            <Form.Item label={getLabel("Comment")} name="comment" rules={REQUIRED_FIELDS_RULES}>
                <Input.TextArea placeholder="Add Notes" autoSize={{ maxRows: 5 }} />
            </Form.Item>
            <Form.Item label={getLabel("Reason")} name="reasonId" rules={REQUIRED_FIELDS_RULES}>
                <Select
                    showSearch={showSearch}
                    filterOption={filterSelectOption}
                    placeholder="Please Select reason"
                    className={styles.FormItem}
                    options={renderSelectOptions(reasons, "name", "id")}
                />
            </Form.Item>
        </Form>
    );
};

CommentWithReasonFields.propTypes = {
    onValuesChange: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    reasons: PropTypes.array,
    showSearch: PropTypes.bool
};

CommentWithReasonFields.defaultProps = {
    reasons: [],
    showSearch: false
};

export default CommentWithReasonFields;
