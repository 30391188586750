export const ERROR = "ERROR";
export const NEW_EVENT = "NEW_EVENT";
export const OPEN_MODAL = "OPEN_MODAL";
export const CARD_DEALT = "CARD_DEALT";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const INITIAL_STATE = "INITIAL_STATE";
export const DISABLE_BUTTON = "DISABLE_BUTTON";
export const NEW_ROUND_STARTED = "NEW_ROUND_STARTED";
export const GAME_SOUND_CHANGED = "GAME_SOUND_CHANGED";
export const SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG";
export const TABLE_STATUS_CHANGED = "TABLE_STATUS_CHANGED";
export const ACTION_STATE_CHANGED = "ACTION_STATE_CHANGED";
export const SET_DEALER_LOGGED_IN = "SET_DEALER_LOGGED_IN";
