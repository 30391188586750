export const INITIAL_PITBOSS_STATE = "INITIAL_PITBOSS_STATE";
export const DEALER_CHANGE = "DEALER_CHANGE";
export const TABLE_NAME_CHANGE = "TABLE_NAME_CHANGE";
export const UPDATE_GAME_IMAGE = "UPDATE_GAME_IMAGE";
export const TABLE_TOTAL_BET_OR_CURRENCY_CHANGED = "TABLE_TOTAL_BET_OR_CURRENCY_CHANGED";
export const ACTION_STATE_CHANGED = "ACTION_STATE_CHANGED";
export const ROUND_ID_CHANGE = "ROUND_ID_CHANGE";
export const PLAYERS_COUNT_CHANGE = "PLAYERS_COUNT_CHANGE";
export const GAME_STATUS_CHANGED = "GAME_STATUS_CHANGED";
export const TABLE_ADDED = "TABLE_ADDED";
export const TABLE_REMOVED = "TABLE_REMOVED";
export const GAME_SOUND_CHANGED = "GAME_SOUND_CHANGED";

export const ERROR = "ERROR";
export const DISCONNECT = "DISCONNECT";
