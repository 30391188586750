export const SPLIT = "SPLIT";
export const RESET = "RESET";
export const ERROR = "ERROR";
export const DOUBLE = "DOUBLE";
export const MAIN_BET = "MAIN_BET";
export const SIDE_BET = "SIDE_BET";
export const INSURANCE = "INSURANCE";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SIDE_BET_WIN = "SIDE_BET_WIN";
export const INITIAL_STATE = "INITIAL_STATE";
export const SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG";
export const PLAYER_LEAVE_BOX = "PLAYER_LEAVE_BOX";
export const NEW_ROUND_STARTED = "NEW_ROUND_STARTED";
export const PLAYER_SEAT_ON_BOX = "PLAYER_SEAT_ON_BOX";
