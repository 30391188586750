import React from "react";
import PropTypes from "prop-types";

const CircledPhone = ({ color = "var(--lc__white_900)", backgroundColor = "var(--lc__gray_850)" }) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 40 40">
            <g transform="translate(-710 -1006)">
                <circle fill={backgroundColor} cx="20" cy="20" r="20" transform="translate(710 1006)" />
                <g className="b" transform="translate(718 1014)" opacity="0.4">
                    <rect width="24" height="24" fill="none" />
                    <path
                        fill={color}
                        d="M6.62,10.79a15.149,15.149,0,0,0,6.59,6.59l2.2-2.2a.994.994,0,0,1,1.02-.24,11.407,11.407,0,0,0,3.57.57,1,1,0,0,1,1,1V20a1,1,0,0,1-1,1A17,17,0,0,1,3,4,1,1,0,0,1,4,3H7.5a1,1,0,0,1,1,1,11.36,11.36,0,0,0,.57,3.57,1,1,0,0,1-.25,1.02Z"
                    />
                </g>
            </g>
        </svg>
    );
};

CircledPhone.propTypes = {
    color: PropTypes.string,
    backgroundColor: PropTypes.string
};

export default CircledPhone;
