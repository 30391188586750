import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss";

const TablePicture = ({ baseUrl, streamImageName, isBlurred }) => {
    const currentImageRef = useRef(streamImageName);

    // this is for animation, to fade out
    useEffect(() => {
        setTimeout(() => {
            currentImageRef.current = streamImageName;
        }, 400);
    }, [streamImageName]);

    return (
        <>
            <picture>
                <source srcSet={`${baseUrl}/${streamImageName}.webp`} type="image/webp" />
                <img
                    src={`${baseUrl}/${streamImageName}.jpg`}
                    alt="image from studio"
                    className={`${styles.GameContentImage} 
                        ${isBlurred ? styles.Blur : ""}     
                        `}
                />
            </picture>
            {currentImageRef.current !== streamImageName && (
                <picture className={styles.FadeOut}>
                    <source srcSet={`${baseUrl}/${currentImageRef.current}.webp`} type="image/webp" />
                    <img
                        src={`${baseUrl}/${streamImageName}.jpg`}
                        alt="image from studio"
                        className={`${styles.GameContentImage} 
                        ${isBlurred ? styles.Blur : ""}     
                        `}
                    />
                </picture>
            )}
        </>
    );
};

TablePicture.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    streamImageName: PropTypes.string,
    isBlurred: PropTypes.bool
};

TablePicture.defaultProps = {
    streamImageName: "",
    isBlurred: false
};

export default TablePicture;
