import React, { useMemo } from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import { antdTableColumnsListCreator } from "@livecasino/core/helpers/metadataCreator";
import { DATE_FORMAT, INITIAL_TABLE_CONFIG } from "shared/constants";
import useCuttingCardsTabData from "../../../../hooks/useCuttingCardsTabData";
import { momentFormatter } from "shared/utils";
import { metadata } from "hooks/useCuttingCardsTabData/reducer";
import styles from "components/NavigationTabs/shared/CuttingCards/index.module.scss";

const SCROLL_DATA = { y: "calc(100% - 39px)" };

const CuttingCards = ({ getAsyncToken, gameKind }) => {
    const { cuttingCards, loading } = useCuttingCardsTabData({ getAsyncToken, gameKind: gameKind });

    const tableConfig = useMemo(
        () => ({
            ...INITIAL_TABLE_CONFIG,
            actions: {
                dateFormatter: function handleDateFormatter(data) {
                    return momentFormatter(data.text, DATE_FORMAT);
                }
            }
        }),
        []
    );

    function onChange(pagination, filters, sorter) {
        console.log("params", pagination, filters, sorter);
    }

    return (
        <div className={styles.CuttingCardsTableWrapper}>
            <Table
                dataSource={cuttingCards}
                columns={antdTableColumnsListCreator(metadata, tableConfig)}
                pagination={false}
                loading={loading}
                className="height100"
                scroll={SCROLL_DATA}
                size="small"
                rowKey={record => record.rowId}
                onChange={onChange}
            />
        </div>
    );
};
CuttingCards.propTypes = {
    getAsyncToken: PropTypes.func.isRequired,
    gameKind: PropTypes.string.isRequired
};

CuttingCards.displayName = "CuttingCardsNavTab";

export default CuttingCards;
