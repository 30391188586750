import React, { useState, useCallback, useMemo } from "react";
import { Table } from "antd";
import ToggleSwitch from "@livecasino/core/v2/components/ToggleSwitch";
import { antdTableColumnsListCreator } from "@livecasino/core/helpers/metadataCreator";
import PropTypes from "prop-types";
import usePlayersTabData from "hooks/ClassicBlackjackHooks/usePlayersTabData";
import { getPlayersDataByBox, getPlayersDataByUser } from "hooks/ClassicBlackjackHooks/usePlayersTabData/helpers";
import { INITIAL_TABLE_CONFIG, MODAL_TYPES, USER_ACTION_TYPES } from "shared/constants";
import { metadataByBox, metadataByUser } from "hooks/ClassicBlackjackHooks/usePlayersTabData/reducer";
import BlockUser from "components/Modals/ModalContent/BlockUser";
import Modal from "@livecasino/core/v2/components/Modal";
import ActionsPopover from "components/ActionsPopover/ClassicBlackjackActionsPopover";
import styles from "./index.module.scss";
import { onCloseHandler, showNotification } from "@livecasino/core/v2/components/Notification";
import InfoMessage from "@livecasino/core/v2/components/InfoMessage";
import { openPlayerReportInNewTab } from "../../../../shared/utils";

const SCROLL_DATA = { y: "calc(100% - 80px)" };

const ActivePlayers = ({ getAsyncToken }) => {
    const [isByUser, setIsByUser] = useState(true);
    const [selectedPlayerToBlock, setSelectedPlayerToBlock] = useState({
        playerName: "",
        playerId: null
    });
    const [playerIdInPopover, setPlayerIdInPopover] = useState(null);

    const onReceiveInfoMessage = useCallback(message => {
        showNotification(<InfoMessage message={message} transparent={false} onClose={onCloseHandler} />);
    }, []);
    const {
        boxes,
        loading,
        unblockDates,
        openModalType,
        handleOpenModal,
        handleBlockUser,
        handleRemoveFromSeat,
        statusChangeReasons,
        handleCloseModal
    } = usePlayersTabData({ getAsyncToken, onReceiveInfoMessage });
    const playersData = isByUser ? getPlayersDataByUser(boxes) : getPlayersDataByBox(boxes);

    const handlePlayerProfileClick = useCallback(playerId => {
        openPlayerReportInNewTab(playerId);
    }, []);

    const handleBlockUserClick = useCallback(
        player => {
            handleOpenModal(MODAL_TYPES.BLOCK_USER);
            setSelectedPlayerToBlock(player);
        },
        [handleOpenModal]
    );

    const onBlockPlayer = useCallback(
        values => {
            handleBlockUser(values, selectedPlayerToBlock?.playerId);
        },
        [selectedPlayerToBlock.playerId, handleBlockUser]
    );

    const handleActionItemClick = useCallback(
        ({ type, data }) => {
            const { player, boxIdToRemove } = data;
            switch (type) {
                case USER_ACTION_TYPES.VIEW: {
                    handlePlayerProfileClick(player.playerId);
                    break;
                }
                case USER_ACTION_TYPES.REMOVE: {
                    setPlayerIdInPopover(null);
                    handleRemoveFromSeat(player.playerId, boxIdToRemove);
                    break;
                }
                case USER_ACTION_TYPES.BLOCK: {
                    handleBlockUserClick(player);

                    break;
                }
                default: {
                    break;
                }
            }
        },
        [handlePlayerProfileClick, handleRemoveFromSeat, handleBlockUserClick]
    );

    const tableConfig = useMemo(
        () => ({
            ...INITIAL_TABLE_CONFIG,
            userActions: {
                view: function getActionsPopover(data) {
                    const { isUser, playerId, boxId, playerName } = data.record;
                    const player = {
                        playerId,
                        playerName
                    };

                    const userOrBoxSequence = isUser ? playerId : String(playerId) + boxId;
                    return (
                        <div
                            onClick={e => {
                                e.stopPropagation();
                                setPlayerIdInPopover(userOrBoxSequence);
                            }}
                        >
                            <ActionsPopover
                                isUser={isUser}
                                boxId={boxId}
                                player={player}
                                togglePopoverCb={setPlayerIdInPopover}
                                onItemClick={handleActionItemClick}
                                selectedPlayerId={playerIdInPopover}
                            />
                        </div>
                    );
                }
            }
        }),
        [handleActionItemClick, playerIdInPopover]
    );

    const columns = useMemo(
        () => antdTableColumnsListCreator(isByUser ? metadataByUser : metadataByBox, tableConfig),
        [isByUser, tableConfig]
    );

    const toggleSwitch = useCallback(() => {
        setIsByUser(prevState => !prevState);
    }, []);
    return (
        <div className={styles.ActivePlayers}>
            <div className={`${styles.SwitchBoxContainer} ${isByUser ? styles.Toggled : ""}`}>
                <span>By User</span>
                <div className={styles.ToggleWrapper}>
                    <ToggleSwitch onToggleSwitch={toggleSwitch} isToggleOn={!isByUser} />
                </div>
                <span>By Box</span>
            </div>

            <Table
                dataSource={playersData}
                columns={columns}
                pagination={false}
                loading={loading}
                className="height100"
                scroll={SCROLL_DATA}
                rowKey="key"
                size="small"
            />

            <Modal open={openModalType === MODAL_TYPES.BLOCK_USER}>
                <BlockUser
                    blockDurations={unblockDates}
                    blockReasons={statusChangeReasons}
                    onSubmit={onBlockPlayer}
                    onClose={handleCloseModal}
                    playerName={selectedPlayerToBlock?.playerName}
                />
            </Modal>
        </div>
    );
};

ActivePlayers.propTypes = {
    getAsyncToken: PropTypes.func.isRequired
};

export default ActivePlayers;
