import { useMemo } from "react";
import getNanoPlayerConfig from "hooks/useVideo/getNanoPlayerConfig";
import getLocalPlayerConfig from "hooks/useVideo/getLocalPlayerConfig";

const useVideo = ({ streamData }) => {
    return useMemo(() => {
        if (!streamData) {
            return {
                nanoPlayerConfig: null,
                publicPlayerConfig: null
            };
        }
        const { nanocosmos: publicStreamEntries, local: localStreamConfig } = streamData;
        const nanoPlayerConfig = getNanoPlayerConfig(publicStreamEntries);
        const publicPlayerConfig = getLocalPlayerConfig(localStreamConfig);
        return {
            nanoPlayerConfig,
            publicPlayerConfig
        };
    }, [streamData]);
};

export default useVideo;
