import React, { useCallback } from "react";
import PropTypes from "prop-types";
import MainModal from "./MainModal";

const RescanLast = ({ onClose, onSubmit }) => {
    const handleOkClick = useCallback(() => {
        onSubmit(false);
    }, [onSubmit]);

    return (
        <MainModal
            onSubmit={handleOkClick}
            onClose={onClose}
            cancelButtonText="No"
            confirmButtonText="Yes"
            title="Rescan Last"
        >
            <p>Are you sure you want to rescan last?</p>
        </MainModal>
    );
};

RescanLast.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default RescanLast;
