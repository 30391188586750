export function getTablesWithUpdatedImage({ state, payload }) {
    const { tableId, streamImage } = payload;
    const games = state.games.map(game => {
        if (game.tableId === tableId) {
            game.streamData = {
                ...game.streamData,
                image: streamImage
            };
        }
        return game;
    });

    return {
        ...state,
        games
    };
}
