import React from "react";
import RadioButtonWithLabel from "@livecasino/core/v2/components/RadioButtonWithLabel";
import PropTypes from "prop-types";
import styles from "./index.module.scss";
import { STREAM_SOURCES } from "../../shared/constants";

const DMSGameStreamSwitchRadioButtons = ({ selectedSource, onSourceChange }) => {
    return (
        <div className={styles.Container}>
            <div className={styles.RadioButton}>
                <RadioButtonWithLabel
                    label="Local Stream (Only Video)"
                    checked={selectedSource === STREAM_SOURCES.LOCAL}
                    onClick={() => onSourceChange(STREAM_SOURCES.LOCAL)}
                />
            </div>
            <div className={styles.RadioButton}>
                <RadioButtonWithLabel
                    label="Game UI & Stream"
                    checked={selectedSource === STREAM_SOURCES.GAME_IFRAME}
                    onClick={() => onSourceChange(STREAM_SOURCES.GAME_IFRAME)}
                />
            </div>
        </div>
    );
};

DMSGameStreamSwitchRadioButtons.propTypes = {
    selectedSource: PropTypes.string.isRequired,
    onSourceChange: PropTypes.func.isRequired
};

export default DMSGameStreamSwitchRadioButtons;
