import React from "react";
import styles from "./index.module.scss";
import MutedIcon from "@livecasino/core/v2/svgs/Mute";

const GameMutedInfo = () => {
    return (
        <div className={styles.MutedInfoContainer}>
            <div className={styles.MutedIconContainer}>
                <MutedIcon color="var(--lc-pitboss__red_500, rgb(255, 0, 0))" />
            </div>
        </div>
    );
};

export default GameMutedInfo;
