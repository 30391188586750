import * as types from "../useCuttingCardsTabData/types";
import { addLastRoundToList } from "../../shared/utils";

export const metadata = [
    {
        action: null,
        colName: "Round Id",
        displayName: "Round Id",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.roundId - b.roundId,
        key: "roundId",
        link: null,
        type: "text"
    },
    {
        action: null,
        colName: "Date",
        displayName: "Date",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "scannedDate",
        link: null,
        type: "date"
    },
    {
        action: null,
        colName: "Dealer Name/Nickname",
        displayName: "Dealer Name/Nickname",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "dealerName",
        link: null,
        type: "text",
        columnAlignment: "center"
    }
];
export const INITIAL_STATE = {
    cuttingCards: [],
    loading: true
};

export default function (state, action) {
    switch (action.type) {
        case types.INITIAL_STATE: {
            const { cuttingCards } = action.payload;
            return { ...state, cuttingCards, loading: false };
        }

        case types.CUTTING_CARD_SCANNED: {
            const { dealerName, scannedDate, roundId } = action.payload;
            const addedCuttingCard = {
                dealerName,
                scannedDate,
                roundId
            };

            const updatedCuttingCards = addLastRoundToList(state.cuttingCards, addedCuttingCard, 20);
            return { ...state, cuttingCards: updatedCuttingCards };
        }
    }
}
