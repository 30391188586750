import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { ERROR_TRANSLATIONS, CLASSIC_BJ_AVAILABLE_SEATS, DATE_FORMAT, STREAM_SOURCES } from "shared/constants";
import useHeaderData from "hooks/ClassicBlackjackHooks/useHeaderData";
import GameFrame from "components/Frames/GameFrame";
import DMSFrame from "components/Frames/DMSFrame";
import Modal from "@livecasino/core/v2/components/Modal";
import getErrorObjectByType from "@livecasino/core/helpers/getErrorObjectByType";
import ErrorContent from "@livecasino/core/v2/Backoffice_DMS_Pitboss/components/ErrorContent/ErrorContent";
import NavigationTabs from "components/NavigationTabs/ClassicBlackJack";
import styles from "./index.module.scss";
import DMSGameDetailsHeader from "../../DMSGameDetailsHeader";
import { momentFormatter } from "../../../shared/utils";
import DMSGameStreamSwitchRadioButtons from "../../DMSGameStreamSwitchRadioButtons";

const GameAndDmsWithTabsBJ = ({ tableId, getAsyncToken, dmsUrl, gameUrl, displayNotification }) => {
    const [selectedStreamSource, setSelectedStreamSource] = useState(STREAM_SOURCES.LOCAL);

    const {
        roundId,
        startTime,
        baseBet,
        sideBet,
        insurance,
        totalBets,
        dealerNickname,
        isGameMuted,
        players,
        viewers,
        betters,
        error,
        tableName,
        streamData
    } = useHeaderData({ getAsyncToken, onReceiveInfoMessage: displayNotification });

    const errorObject = useMemo(() => {
        if (!error) {
            return null;
        }
        return getErrorObjectByType({ error, translation: ERROR_TRANSLATIONS });
    }, [error]);

    return (
        <>
            <div className={`${styles.PitBossNewWindow} ${error ? "blur-background" : ""}`}>
                <DMSGameDetailsHeader>
                    <DMSGameDetailsHeader.Item propertyName="Round ID" value={roundId} />
                    <DMSGameDetailsHeader.Item propertyName="Total Bet" value={totalBets} />
                    <DMSGameDetailsHeader.Item
                        propertyName="Start Time"
                        value={momentFormatter(startTime, DATE_FORMAT)}
                    />
                    <DMSGameDetailsHeader.Item propertyName="Dealer NickName" value={dealerNickname} />
                    <DMSGameDetailsHeader.Item propertyName="Base Bet" value={baseBet} />
                    <DMSGameDetailsHeader.Item propertyName="Side Bet" value={sideBet} />
                    <DMSGameDetailsHeader.Item propertyName="Insurance Bet" value={insurance} />
                    <div className={styles.StreamSwitchRadioButtons}>
                        <DMSGameStreamSwitchRadioButtons
                            selectedSource={selectedStreamSource}
                            onSourceChange={setSelectedStreamSource}
                        />
                    </div>
                </DMSGameDetailsHeader>
                <div className={styles.IFramesContainer}>
                    <GameFrame
                        selectedStreamSource={selectedStreamSource}
                        streamData={streamData}
                        iframeUrl={gameUrl}
                        activePlayersCount={players}
                        betters={betters}
                        viewers={viewers}
                        availableSeatsCount={CLASSIC_BJ_AVAILABLE_SEATS}
                        isGameMuted={isGameMuted}
                    />
                    <DMSFrame iframeURL={dmsUrl} />
                </div>
                <div className={styles.TabsWrapper}>
                    <NavigationTabs tableId={tableId} getAsyncToken={getAsyncToken} tableName={tableName} />
                </div>
            </div>
            <Modal open={Boolean(error)} backdropFilterValue="4px" backdropBackgroundColor="transparent">
                <div className={styles.ErrorContentWrapper}>
                    <ErrorContent title={errorObject?.title} textsArray={errorObject?.text} />
                </div>
            </Modal>
        </>
    );
};
GameAndDmsWithTabsBJ.propTypes = {
    tableId: PropTypes.string.isRequired,
    getAsyncToken: PropTypes.func.isRequired,
    dmsUrl: PropTypes.string.isRequired,
    gameUrl: PropTypes.string.isRequired,
    displayNotification: PropTypes.func.isRequired
};

export default GameAndDmsWithTabsBJ;
