import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "components/Frames/index.module.scss";
import ViewersPlayers from "@livecasino/core/v2/components/ViewersPlayersBetters";
import GameMutedInfo from "../../GameMutedInfo";
import useVideo from "../../../hooks/useVideo";
import LocalStreamPlayer from "../../LocalStreamPlayer";
import { STREAM_SOURCES } from "../../../shared/constants";
import useVolume from "@livecasino/core/hooks/useVolume";
import SoundSlider from "../../SoundSlider";

const GameFrame = ({
    activePlayersCount = null,
    iframeUrl,
    isGameMuted = false,
    availableSeatsCount = null,
    betters = null,
    viewers = null,
    streamData = null,
    selectedStreamSource = null
}) => {
    const [isPlayerInitialized, setIsPlayerInitialized] = useState(false);
    const { publicPlayerConfig } = useVideo({ streamData });
    const isLocalStreamSelected = selectedStreamSource === STREAM_SOURCES.LOCAL;
    const isGameUiSelected = selectedStreamSource === STREAM_SOURCES.GAME_IFRAME;
    const { volume, setVolumeChange, toggleMuted, muted } = useVolume(0);

    const handleStreamClick = () => {
        if (isPlayerInitialized && muted) {
            toggleMuted();
        }
    };

    return (
        <div className={styles.StreamWrapper}>
            <div className={styles.Iframe}>
                {isLocalStreamSelected && (
                    <div className={styles.LocalStreamContainer} onClickCapture={handleStreamClick}>
                        {isPlayerInitialized && (
                            <div className={styles.SoundSliderContainer}>
                                <SoundSlider
                                    volume={volume}
                                    onVolumeChange={setVolumeChange}
                                    onVolumeMuteToggle={toggleMuted}
                                />
                            </div>
                        )}
                        <LocalStreamPlayer
                            volume={volume}
                            config={publicPlayerConfig}
                            isPlayerInitialized={isPlayerInitialized}
                            setIsPlayerInitialized={setIsPlayerInitialized}
                        />
                    </div>
                )}

                {isGameUiSelected && <iframe src={iframeUrl} scrolling="no" width="100%" height="100%" />}

                {isGameMuted && (
                    <div className={styles.Muted}>
                        <GameMutedInfo />
                    </div>
                )}

                <div className={styles.PlayersSeatsContainer}>
                    <ViewersPlayers
                        availableSeats={availableSeatsCount}
                        players={activePlayersCount}
                        betters={betters}
                        viewers={viewers}
                    />
                </div>
            </div>
        </div>
    );
};

GameFrame.propTypes = {
    activePlayersCount: PropTypes.number,
    betters: PropTypes.number,
    viewers: PropTypes.number,
    availableSeatsCount: PropTypes.number,
    isGameMuted: PropTypes.bool,
    iframeUrl: PropTypes.string.isRequired,
    streamData: PropTypes.object,
    selectedStreamSource: PropTypes.string
};

export default GameFrame;
