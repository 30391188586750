import React, { useMemo } from "react";
import styles from "./index.module.scss";
import PropTypes from "prop-types";
import { Table } from "antd";
import { cardRender, momentFormatter } from "shared/utils";
import { DATE_FORMAT, INITIAL_TABLE_CONFIG } from "shared/constants";
import { antdTableColumnsListCreator } from "@livecasino/core/helpers/metadataCreator";

const columns = [
    {
        displayName: "Action",
        dataIndex: "action",
        key: "action"
    },
    {
        displayName: "Player",
        dataIndex: "player",
        key: "player"
    },
    {
        displayName: "Date",
        dataIndex: "date",
        key: "date",
        type: "date"
    },
    {
        displayName: "Card",
        dataIndex: "card",
        key: "card",
        type: "card",
        columnAlignment: "center"
    },
    {
        displayName: "Cost",
        dataIndex: "cost",
        key: "cost",
        type: "number",
        decimalPrecision: 2
    }
];

const SCROLL_DATA = { y: "calc(100% - 39px)" };

const ActionsTable = ({ history, loading }) => {
    const tableConfig = useMemo(
        () => ({
            ...INITIAL_TABLE_CONFIG,
            actions: {
                dateFormatter: function handleDateFormatter(data) {
                    return momentFormatter(data.text, DATE_FORMAT);
                },
                cardRender: cardRender
            },

            breakPoints: {
                Action: 150,
                Player: 150,
                Cost: 100
            }
        }),
        []
    );

    const mappedColumns = antdTableColumnsListCreator(columns, tableConfig);
    return (
        <div className={styles.ActionHistory}>
            <Table
                dataSource={history}
                columns={mappedColumns}
                pagination={false}
                size="small"
                loading={loading}
                className="height100"
                scroll={SCROLL_DATA}
                rowKey={record => record.date}
            />
        </div>
    );
};
ActionsTable.propTypes = {
    history: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
};

export default ActionsTable;
