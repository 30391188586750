import React, { useMemo } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import InfoMessage from "@livecasino/core/v2/components/InfoMessage";
import GameAndDmsWithTabs from "components/DMSPitBoss/GameAndDmsWithTabs";
import GameAndDmsWithTabsBJ from "components/DMSPitBoss/GameAndDmsWithTabsBJ";
import { getNavigationTabComponent } from "./helpers";
import { onCloseHandler, showNotification } from "@livecasino/core/v2/components/Notification";
import { GAME_TYPE_DISPLAY_NAMES, GAME_TYPES } from "@livecasino/core/constants/gameTypes";
import { GAME_TYPE_URLS } from "shared/constants";

const displayNotification = message =>
    showNotification(<InfoMessage message={message} transparent={false} onClose={onCloseHandler} />);

const DMSPitBoss = ({ tableId, getAsyncToken, token, gameType, refreshToken }) => {
    const dmsUrl = `${GAME_TYPE_URLS[gameType]?.dms}?tableId=${tableId}&gameType=${gameType}&isOpenedFromPitBoss=true`;
    const gameUrl = useMemo(
        () =>
            `${GAME_TYPE_URLS[gameType]?.game}?` +
            queryString.stringify(
                {
                    tableId,
                    token,
                    refreshToken,
                    partnerPrefix: "PITBOSS",
                    isFullScreenIconVisible: "0"
                },
                { encode: false }
            ),
        [gameType, tableId, refreshToken, token]
    );
    const isSupportedGameType = useMemo(() => Object.values(GAME_TYPES).includes(gameType), [gameType]);
    const isBlackjackType = gameType === GAME_TYPES.AMERICAN_BLACKJACK || gameType === GAME_TYPES.IMMERSIVE_BLACKJACK;
    const { tabsContentComponent } = useMemo(() => getNavigationTabComponent(gameType), [gameType]);

    if (!isSupportedGameType) {
        return <span>Unknown game kind</span>;
    }

    if (isBlackjackType) {
        return (
            <GameAndDmsWithTabsBJ
                displayNotification={displayNotification}
                dmsUrl={dmsUrl}
                gameUrl={gameUrl}
                tableId={tableId}
                getAsyncToken={getAsyncToken}
            />
        );
    }
    return (
        <GameAndDmsWithTabs
            displayNotification={displayNotification}
            dmsUrl={dmsUrl}
            gameUrl={gameUrl}
            tableId={tableId}
            getAsyncToken={getAsyncToken}
            tabsContentComponent={tabsContentComponent}
            gameType={GAME_TYPE_DISPLAY_NAMES[gameType]}
        />
    );
};

DMSPitBoss.propTypes = {
    gameKind: PropTypes.string.isRequired,
    gameType: PropTypes.string.isRequired,
    tableId: PropTypes.string.isRequired,
    getAsyncToken: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    refreshToken: PropTypes.string.isRequired
};

export default DMSPitBoss;
