export const blackjackMetadata = [
    {
        action: null,
        colName: "Round Id",
        displayName: "Round Id",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "roundId",
        link: null,
        type: "text"
    },
    {
        action: null,
        colName: "Game Start time ",
        displayName: "Game Start Time ",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "gameStartTime",
        link: null,
        type: "date"
    },
    {
        action: null,
        colName: "Game End Time",
        displayName: "Game End Time",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "gameEndTime",
        link: null,
        type: "date"
    },
    {
        action: null,
        colName: "Total Bet",
        displayName: "Total Bet",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.totalBet - b.totalBet,
        key: "totalBet",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Total Win",
        displayName: "Total Win",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.totalWin - b.totalWin,
        key: "totalWin",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "GGR",
        displayName: "GGR",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.ggr - b.ggr,
        key: "ggr",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Margin %",
        displayName: "Margin %",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.ggrPercent - b.ggrPercent,
        key: "ggrPercent",
        link: null,
        type: "number"
    },
    {
        action: null,
        colName: "Dealer Name",
        displayName: "Dealer Name",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "dealerName",
        link: null,
        type: "text"
    }
];
