import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss";

const GameDetail = ({ propertyName, value }) => {
    return (
        <span className={styles.Column}>
            {propertyName}: <span className={styles.HighLighted}>{value}</span>
        </span>
    );
};

GameDetail.propTypes = {
    propertyName: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
GameDetail.defaultProps = {
    value: null
};
export default GameDetail;
