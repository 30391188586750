import { useEffect } from "react";
import useCustomReducer from "@livecasino/core/hooks/useCustomReducer";
import useSignalR from "@livecasino/core/hooks/useSignalR";
import reducer, { INITIAL_STATE } from "./reducer";
import * as types from "./types";
import { ERROR_TYPES } from "@livecasino/core/constants/errorTypes";
import setHubConnectionLogger from "@livecasino/core/helpers/setHubConnectionLogger";
import { ACTION_STATES, ACTION_TYPES_TO_PLAY_SOUND, PITBOSS_SERVER_URL } from "../../shared/constants";
import queryString from "query-string";
import useSound from "@livecasino/core/hooks/useSound";
import alertSound from "../../assets/sounds/alertSound.mp3";

const PLAYLIST_OBJECT = { alertSound };

function usePitBossData({ getAsyncToken, tableIds }) {
    const playListRef = useSound({ volume: 1, playListObject: PLAYLIST_OBJECT });
    const [state, dispatch] = useCustomReducer(reducer, INITIAL_STATE, true);
    const connectionURL =
        PITBOSS_SERVER_URL + `/lobby?${queryString.stringify({ tableIds }, { arrayFormat: "comma", encode: false })}`;

    const { hubConnection, connectionStatus, areListenersSetRef } = useSignalR({
        url: connectionURL,
        getAsyncToken
    });

    useEffect(() => {
        if (!tableIds || !Array.isArray(tableIds) || tableIds.length === 0) {
            dispatch({
                type: types.ERROR,
                payload: { error: "Please select tables from Back Office" }
            });
        }
    }, [tableIds, dispatch]);

    useEffect(() => {
        if (!hubConnection || areListenersSetRef.current || !connectionStatus.isConnected) {
            return;
        }
        setHubConnectionLogger(hubConnection, "PitBoss");

        hubConnection.on("InitialPitbossState", data => {
            dispatch({
                type: types.INITIAL_PITBOSS_STATE,
                payload: data
            });
        });

        hubConnection.on("DealerChanged", data => {
            const { tableId, dealerNickname } = data;

            dispatch({
                type: types.DEALER_CHANGE,
                payload: { tableId, dealerNickname }
            });
        });

        hubConnection.on("TableNameChanged", data => {
            const { tableId, tableName } = data;

            dispatch({
                type: types.TABLE_NAME_CHANGE,
                payload: { tableId, tableName }
            });
        });

        hubConnection.on("ImageUpdated", data => {
            dispatch({
                type: types.UPDATE_GAME_IMAGE,
                payload: data
            });
        });

        hubConnection.on("TotalBetAmountOrCurrencyChanged", data => {
            const { tableId, totalBetAmount, currencyCode } = data;

            dispatch({
                type: types.TABLE_TOTAL_BET_OR_CURRENCY_CHANGED,
                payload: { tableId, totalBetAmount, currencyCode }
            });
        });

        hubConnection.on("RoundIdChanged", data => {
            const { tableId, roundId } = data;

            dispatch({
                type: types.ROUND_ID_CHANGE,
                payload: { tableId, roundId }
            });
        });

        hubConnection.on("GameSoundChanged", data => {
            const { tableId, isMuted: isGameMuted } = data;

            dispatch({
                type: types.GAME_SOUND_CHANGED,
                payload: { tableId, isGameMuted }
            });
        });

        hubConnection.on("ActionStateChanged", data => {
            const { tableId, type, state } = data;
            if (state === ACTION_STATES.ACTIVE && ACTION_TYPES_TO_PLAY_SOUND.includes(type)) {
                playListRef.current.alertSound?.play();
            }
            dispatch({
                type: types.ACTION_STATE_CHANGED,
                payload: { tableId, type, state }
            });
        });

        hubConnection.on("PlayersCountChanged", data => {
            const { tableId, players, viewers, betters } = data;

            dispatch({
                type: types.PLAYERS_COUNT_CHANGE,
                payload: { tableId, players, viewers, betters }
            });
        });

        hubConnection.on("TableStatusChanged", data => {
            const {
                tableId,
                status: { isActive, isPaused, timer }
            } = data;

            dispatch({
                type: types.GAME_STATUS_CHANGED,
                payload: { tableId, isActive, isPaused, timer }
            });
        });

        hubConnection.on("TableAdded", data => {
            const { game } = data;
            dispatch({
                type: types.TABLE_ADDED,
                payload: { game }
            });
        });

        hubConnection.on("TableRemoved", data => {
            const { tableId } = data;
            dispatch({
                type: types.TABLE_REMOVED,
                payload: { tableId }
            });
        });
        hubConnection.on("ManuallyClosed", () => {
            dispatch({ type: types.ERROR, payload: ERROR_TYPES.SESSION_EXPIRED });
        });
        areListenersSetRef.current = true;
    }, [hubConnection, areListenersSetRef, dispatch, connectionStatus.isConnected, playListRef]);

    useEffect(() => {
        if (!hubConnection || !connectionStatus.isConnected || !areListenersSetRef.current) {
            return;
        }
        hubConnection.invoke("GetInitialState").catch(error => {
            dispatch({
                type: types.ERROR,
                payload: error
            });
        });
    }, [areListenersSetRef, dispatch, connectionStatus.isConnected, hubConnection]);

    useEffect(() => {
        dispatch({
            type: types.ERROR,
            payload: connectionStatus.error
        });
    }, [connectionStatus.error, dispatch]);

    return {
        ...state
    };
}

export default usePitBossData;
