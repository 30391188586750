import React, { useCallback } from "react";
import RescanCard from "components/NavigationTabs/shared/RescanCard";
import Actions from "./Actions";
import EventsTable from "./ActionsTable";
import useActionsTabData from "hooks/ClassicBlackjackHooks/useClassicBlackjackActionsTabData";
import PropTypes from "prop-types";
import { showNotification, onCloseHandler } from "@livecasino/core/v2/components/Notification";
import InfoMessage from "@livecasino/core/v2/components/InfoMessage";
import Modal from "@livecasino/core/v2/components/Modal";
import { MODAL_TYPES } from "../../../../shared/constants";
import ModalContent from "../../../Modals/ModalContent";

import styles from "./index.module.scss";

const columns = [
    {
        displayName: "Card",
        dataIndex: "card",
        key: "card",
        type: "card",
        columnAlignment: "center"
    },
    {
        displayName: "Box",
        dataIndex: "box",
        key: "boxId"
    },
    {
        displayName: "Time",
        dataIndex: "time",
        key: "time",
        type: "date"
    }
];

const ActionsTab = ({ getAsyncToken, tableName }) => {
    const onReceiveInfoMessage = useCallback(message => {
        showNotification(<InfoMessage message={message} transparent={false} onClose={onCloseHandler} />);
    }, []);

    const {
        actions,
        cards,
        events,
        gameConfig,
        loading,
        handleRescanCards,
        openModalType,
        closedStatusReasons,
        shufflers,
        cancelGameStatusReasons,
        handleOpenModal,
        handleCloseModal,
        handleCancelGame,
        handleUpdateGameStatus,
        handleGameSoundChange,
        handleClearCards,
        handleMistakeClick,
        handlePauseOrResumeGame,
        pendingActions,
        handleMistakeInfoModalSubmit,
        handleCardChangePopupFinish,
        cardReasons,
        mistakeReasons,
        technicalReasons,
        dealerInfos,
        handleDealerLogin,
        isDealerLoggedIn,
        recordedStreamUrl,
        handleCaptureThumbnail,
        cardColors
    } = useActionsTabData({ getAsyncToken, onReceiveInfoMessage });

    const isModalOpen = MODAL_TYPES.hasOwnProperty(openModalType);

    const handleSubmitMistake = useCallback(
        modalInfo => {
            handleCloseModal();
            handleMistakeInfoModalSubmit(modalInfo);
        },
        [handleMistakeInfoModalSubmit, handleCloseModal]
    );

    return (
        <div className={styles.Container}>
            <div className={styles.RescanCardsContainer}>
                <RescanCard
                    tableColumns={columns}
                    rescanDetails={cards}
                    loading={loading}
                    onRescanButtonsClick={handleOpenModal}
                    buttonsPending={pendingActions}
                />
            </div>
            <div className={styles.Actions}>
                <Actions
                    actions={actions}
                    onActionButtonsClick={handleOpenModal}
                    onMistakesClick={handleMistakeClick}
                    buttonsPending={pendingActions}
                    gameConfig={gameConfig}
                    tableName={tableName}
                    recordedStreamUrl={recordedStreamUrl}
                    onCaptureThumbnail={handleCaptureThumbnail}
                />
            </div>
            <Modal open={isModalOpen}>
                <ModalContent
                    type={openModalType}
                    onClose={handleCloseModal}
                    closedStatusReasons={closedStatusReasons}
                    cancelGameStatusReasons={cancelGameStatusReasons}
                    onCancelGame={handleCancelGame}
                    onPauseGame={handlePauseOrResumeGame}
                    onMuteGame={handleGameSoundChange}
                    gameConfig={gameConfig}
                    onCloseGame={handleUpdateGameStatus}
                    onClearCards={handleClearCards}
                    onSubmitMistake={handleSubmitMistake}
                    onSubmitCardsChange={handleCardChangePopupFinish}
                    cardReasons={cardReasons}
                    technicalReasons={technicalReasons}
                    mistakeReasons={mistakeReasons}
                    shufflers={shufflers}
                    dealerInfos={dealerInfos}
                    onDealerLogin={handleDealerLogin}
                    isDealerLoggedIn={isDealerLoggedIn}
                    handleRescanCards={handleRescanCards}
                    cardColors={cardColors}
                />
            </Modal>
            <div className={styles.EventsTable}>
                <EventsTable history={events} loading={loading} />
            </div>
        </div>
    );
};

ActionsTab.propTypes = {
    getAsyncToken: PropTypes.func.isRequired,
    tableName: PropTypes.string.isRequired
};

export default ActionsTab;
