import React, { useState } from "react";
import { Input, Row } from "antd";
import PropTypes from "prop-types";
import MainModal from "./MainModal";
import styles from "components/Modals/styles.module.scss";
import Col from "antd/es/grid/col";
import Dice from "@livecasino/core/v2/svgs/Dice";
import { checkDicesValidNumber } from "../../../shared/utils";

const WRONG_DICE_NUMBER_ERROR_MESSAGE = "Please write correct Dice Number";

const MODAL_TITLE = "WINNING OUTCOME";

const CANCEL_BUTTON_TEXT = "No";

const CONFIRM_BUTTON_TEXT = "Yes";

const DICE_NAMES = {
    FIRST_DICE: "dice1",
    SECOND_DICE: "dice2",
    THIRD_DICE: "dice3"
};

const SetDiceOutcome = ({ onClose, onSubmit }) => {
    const [state, setState] = useState({
        dicesValuesObj: {
            [DICE_NAMES.FIRST_DICE]: 1,
            [DICE_NAMES.SECOND_DICE]: 1,
            [DICE_NAMES.THIRD_DICE]: 1
        }
    });

    const areAllValidValues = checkDicesValidNumber(state.dicesValuesObj);

    function handleChange(e) {
        const { name, value } = e.target;

        setState(prevState => {
            return {
                ...prevState,
                dicesValuesObj: {
                    ...prevState.dicesValuesObj,
                    [name]: value ? Number(value) : ""
                }
            };
        });
    }

    function handleSubmit() {
        onSubmit([state.dicesValuesObj.dice1, state.dicesValuesObj.dice2, state.dicesValuesObj.dice3]);
    }

    return (
        <MainModal
            onSubmit={handleSubmit}
            onClose={onClose}
            cancelButtonText={CANCEL_BUTTON_TEXT}
            confirmButtonText={CONFIRM_BUTTON_TEXT}
            title={MODAL_TITLE}
            confirmButtonDisabled={!areAllValidValues}
        >
            <span>If you want to set an outcome, please input the required fields and click yes</span>
            <div className={styles.FormContainer}>
                <Row justify="space-between">
                    <Col span={7}>
                        <Input
                            value={state.dicesValuesObj.dice1}
                            name={DICE_NAMES.FIRST_DICE}
                            min={1}
                            max={6}
                            onChange={handleChange}
                            type="number"
                        />
                    </Col>
                    <Col span={7}>
                        <Input
                            value={state.dicesValuesObj.dice2}
                            name={DICE_NAMES.SECOND_DICE}
                            min={1}
                            max={6}
                            onChange={handleChange}
                            type="number"
                        />
                    </Col>
                    <Col span={7}>
                        <Input
                            value={state.dicesValuesObj.dice3}
                            name={DICE_NAMES.THIRD_DICE}
                            min={1}
                            max={6}
                            onChange={handleChange}
                            type="number"
                        />
                    </Col>
                </Row>
            </div>
            <div className={styles.ErrorContainer}>
                {!areAllValidValues && <span className={styles.ErrorMessages}>{WRONG_DICE_NUMBER_ERROR_MESSAGE}</span>}
            </div>

            <div className={styles.TripleDicesContainer}>
                <div className={styles.TripleDices}>
                    <div className={styles.Dice}>
                        <Dice value={state.dicesValuesObj.dice1} />
                    </div>
                    <div className={styles.Dice}>
                        <Dice value={state.dicesValuesObj.dice2} />
                    </div>
                    <div className={styles.Dice}>
                        <Dice value={state.dicesValuesObj.dice3} />
                    </div>
                </div>
            </div>
        </MainModal>
    );
};

SetDiceOutcome.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default SetDiceOutcome;
