export function initialState({ payload }) {
    const {
        dealerNickname,
        roundId,
        startTime,
        totalBets,
        players,
        betters,
        viewers,
        isGameMuted,
        tableName,
        streamData
    } = payload;
    return {
        dealerNickname,
        roundId,
        startTime,
        totalBets,
        players,
        betters,
        viewers,
        isGameMuted,
        streamData,
        tableName
    };
}

export function roundIdChanged({ payload, state }) {
    const { roundId, gameStartTime: startTime } = payload;
    return {
        ...state,
        roundId,
        startTime,
        totalBet: 0
    };
}

export function gameSoundChanged({ payload, state }) {
    const { isMuted: isGameMuted } = payload;
    return { ...state, isGameMuted };
}

export function dealerChanged({ payload, state }) {
    const { dealerNickname } = payload;
    return { ...state, dealerNickname };
}

export function playersCountChanged({ payload, state }) {
    const { players, betters, viewers } = payload;
    return { ...state, players, betters, viewers };
}

export function totalBetsAmount({ payload, state }) {
    const totalBets = payload;
    return { ...state, totalBets };
}

export function error({ state, payload }) {
    return {
        ...state,
        error: payload
    };
}
