import * as types from "./types";
import {
    getUpdatedBoxes,
    removePlayerFromBox,
    addPlayerToBox,
    updateSideBets,
    getBoxesWithUpdatedSideBetWins,
    getBlockUserReasons
} from "./helpers";
import { getBlockUserDurations } from "../../../shared/utils";

export const metadataByUser = [
    {
        action: null,
        colName: "Player Nickname",
        displayName: "Player Nickname",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "playerName",
        link: null
    },
    {
        action: null,
        colName: "Partner Name",
        displayName: "Partner Name",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "partnerName",
        link: null
    },
    {
        action: null,
        colName: "Player ID",
        displayName: "Player ID",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "playerId",
        link: null
    },
    {
        action: null,
        colName: "Total Bet",
        displayName: "Total Bet",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.totalBet - b.totalBet,
        key: "totalBet",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Base Bets",
        displayName: "Base Bets",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.baseBets - b.baseBets,
        key: "baseBets",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Side Bets",
        displayName: "Side Bets",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.sideBets - b.sideBets,
        key: "sideBets",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Side Bet Win",
        displayName: "Side Bet Win",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.sideBetsWin - b.sideBetsWin,
        key: "sideBetsWin",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Double Amount",
        displayName: "Double Amount",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.doubleAmount - b.doubleAmount,
        key: "doubleAmount",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Insurance",
        displayName: "Insurance",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.insurance - b.insurance,
        key: "insurance",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Split Amount",
        displayName: "Split Amount",
        isEditable: false,
        isFilterable: false,
        isSortable: (a, b) => a.splitAmount - b.splitAmount,
        key: "splitAmount",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: "view",
        colName: null,
        displayName: "Action",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "userAction",
        link: null,
        type: "userAction"
    }
];
export const metadataByBox = [
    {
        action: null,
        colName: "Box Id",
        displayName: "Player Box Id",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "boxId",
        link: null
    },
    {
        action: null,
        colName: "Player Nickname",
        displayName: "Player Nickname",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "playerName",
        link: null
    },
    {
        action: null,
        colName: "Partner Name",
        displayName: "Partner Name",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "partnerName",
        link: null
    },
    {
        action: null,
        colName: "Player ID",
        displayName: "Player ID",
        isEditable: false,
        isFilterable: false,
        isSortable: false,
        key: "playerId",
        link: null
    },
    {
        action: null,
        colName: "Total Bet",
        displayName: "Total Bet",
        isEditable: false,
        isFilterable: false,
        isSortable: true,
        key: "totalBet",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Base Bets",
        displayName: "Base Bets",
        isEditable: false,
        isFilterable: false,
        isSortable: true,
        key: "baseBets",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Side Bets",
        displayName: "Side Bets",
        isEditable: false,
        isFilterable: false,
        isSortable: true,
        key: "sideBets",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Side Bet Win",
        displayName: "Side Bet Win",
        isEditable: false,
        isFilterable: false,
        isSortable: true,
        key: "sideBetsWin",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Double Amount",
        displayName: "Double Amount",
        isEditable: false,
        isFilterable: false,
        isSortable: true,
        key: "doubleAmount",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Insurance",
        displayName: "Insurance",
        isEditable: false,
        isFilterable: false,
        isSortable: true,
        key: "insurance",
        link: null,
        type: "number",
        decimalPrecision: 2
    },
    {
        action: null,
        colName: "Split Amount",
        displayName: "Split Amount",
        isEditable: false,
        isFilterable: false,
        isSortable: true,
        key: "splitAmount",
        link: null,
        type: "number",
        decimalPrecision: 2
    }
];
export const INITIAL_STATE = {
    boxes: [],
    loading: true,
    error: null,
    openModalType: null,
    statusChangeReasons: [],
    cancelGameStatusReasons: [],
    unblockDates: []
};

export default function (state, action) {
    const { payload } = action;

    switch (action.type) {
        case types.INITIAL_STATE: {
            const { boxes, statusChangeReasons, cancelGameStatusReasons, unblockDates } = payload;
            return {
                ...state,
                boxes,
                statusChangeReasons: getBlockUserReasons(statusChangeReasons),
                cancelGameStatusReasons,
                unblockDates: getBlockUserDurations(unblockDates),
                loading: false
            };
        }

        case types.PLAYER_SEAT_ON_BOX: {
            const {
                betAmount1,
                betAmount2,
                boxId,
                doubleAmount,
                insurance,
                partnerName,
                playerId,
                playerName,
                sideBets,
                sideBetsWin,
                splitAmount
            } = payload;

            const playerInfo = {
                betAmount1,
                betAmount2,
                boxId,
                doubleAmount,
                insurance,
                partnerName,
                playerId,
                playerName,
                sideBets,
                sideBetsWin,
                splitAmount
            };
            const updatedBoxes = addPlayerToBox(state.boxes, playerInfo);
            return { ...state, boxes: updatedBoxes };
        }

        case types.PLAYER_LEAVE_BOX: {
            const { boxId } = payload;
            const updatedBoxes = removePlayerFromBox(state.boxes, boxId);

            return { ...state, boxes: updatedBoxes };
        }

        case types.NEW_ROUND_STARTED: {
            const { boxes } = action.payload;
            return { ...state, boxes };
        }

        case types.MAIN_BET: {
            const { boxId, amount } = payload;
            const updatedBoxInfo = { boxId, amount };
            const updatedBoxes = getUpdatedBoxes(state.boxes, updatedBoxInfo, "betAmount1");
            return { ...state, boxes: updatedBoxes };
        }

        case types.SIDE_BET: {
            const { boxId, sideBet, amount } = payload;
            const sideBetData = { boxId, sideBet, amount };
            const updatedBoxes = updateSideBets(state.boxes, sideBetData);
            return { ...state, boxes: updatedBoxes };
        }
        case types.SIDE_BET_WIN: {
            const { boxId, sideBet, amount } = payload;
            const sideBetWinData = { boxId, sideBet, amount };
            const updatedBoxes = getBoxesWithUpdatedSideBetWins(state.boxes, sideBetWinData);
            return { ...state, boxes: updatedBoxes };
        }

        case types.DOUBLE: {
            const { boxId, amount } = payload;
            const updatedData = { boxId, amount };
            const updatedBoxes = getUpdatedBoxes(state.boxes, updatedData, "doubleAmount");
            return { ...state, boxes: updatedBoxes };
        }
        case types.INSURANCE: {
            const { boxId, amount } = payload;
            const updatedData = { boxId, amount };
            const updatedBoxes = getUpdatedBoxes(state.boxes, updatedData, "insurance");
            return { ...state, boxes: updatedBoxes };
        }

        case types.SPLIT: {
            const { boxId, amount } = payload;
            const updatedData = { boxId, amount };
            const updatedBoxes = getUpdatedBoxes(state.boxes, updatedData, "splitAmount");
            return { ...state, boxes: updatedBoxes };
        }

        case types.OPEN_MODAL: {
            return { ...state, openModalType: payload };
        }

        case types.CLOSE_MODAL: {
            return { ...state, openModalType: null };
        }

        case types.RESET: {
            return action.payload;
        }

        case types.ERROR: {
            return { ...state };
        }
    }
}
