import React from "react";
import PropTypes from "prop-types";
import MainModal from "./MainModal";

const ClearCards = ({ onSubmit, onClose }) => {
    return (
        <MainModal
            onSubmit={onSubmit}
            onClose={onClose}
            cancelButtonText="No"
            confirmButtonText="Yes"
            title="Clear Cards"
        >
            Are you sure you want to clear cards?
        </MainModal>
    );
};

ClearCards.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default ClearCards;
