import React from "react";
import styles from "./GameSettings.module.scss";
import FullScreen from "../../svgs/FullScreenPB";
import PropTypes from "prop-types";
import SoundSlider from "../SoundSlider";

const GameSettings = ({ onZoomIn, onVolumeMuteToggle, isVideoZoomed, onVolumeChange, volume, onOpenDms }) => {
    return (
        <div className={styles.GameSettings}>
            <div className={styles.ControlsRow}>
                <SoundSlider volume={volume} onVolumeMuteToggle={onVolumeMuteToggle} onVolumeChange={onVolumeChange} />
                <div className={styles.VideoSecondLayerRightSide}>
                    <div className={[styles.FullScreenButton, styles.GameSettingsButton].join(" ")} onClick={onZoomIn}>
                        <FullScreen color="var(--lc-pitboss__black_900, rgb(0, 0, 0))" isFullScreen={isVideoZoomed} />
                    </div>
                    <div className={[styles.DMSButtonWrapper, styles.GameSettingsButton].join(" ")} onClick={onOpenDms}>
                        DMS
                    </div>
                </div>
            </div>
        </div>
    );
};
GameSettings.propTypes = {
    onZoomIn: PropTypes.func.isRequired,
    onVolumeMuteToggle: PropTypes.func.isRequired,
    isVideoZoomed: PropTypes.bool,
    volume: PropTypes.number.isRequired,
    onVolumeChange: PropTypes.func,
    onOpenDms: PropTypes.func.isRequired
};
GameSettings.defaultProps = {
    isVideoZoomed: false,
    onZoomIn: Function.prototype,
    onVolumeMuteToggle: Function.prototype,
    onVolumeChange: Function.prototype
};

export default GameSettings;
