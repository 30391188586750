import * as types from "./types";
import { initialState, roundAdded } from "./reducerFunctions";

export const INITIAL_STATE = {
    lastRounds: [],
    loading: true
};

export default function (state, action) {
    const { type, payload } = action;

    switch (type) {
        case types.INITIAL_STATE: {
            return initialState({ payload, state });
        }

        case types.ROUND_ADDED: {
            return roundAdded({ payload, state });
        }
    }
}
