import * as types from "./types";
import { getTablesWithUpdatedImage } from "./reducerFunctions";

export const INITIAL_STATE = {
    games: [],
    loading: true,
    error: null
};

/**
 initial game's properties
 {
    streamData: {
        nanocosmos: [{}, {}, {}],
        local: {}
    },
    tableId: string
    mistakes: {
        MISTAKE_TYPES.mistake: MISTAKE_STATES.idle,
        MISTAKE_TYPES.card: MISTAKE_STATES.idle,
        MISTAKE_TYPES.chat: MISTAKE_STATES.idle,
        MISTAKE_TYPES.cardChange: MISTAKE_STATES.idle,
        MISTAKE_TYPES.change: MISTAKE_STATES.idle,
        MISTAKE_TYPES.technical: MISTAKE_STATES.idle,
        MISTAKE_TYPES.talk: MISTAKE_STATES.idle,
        MISTAKE_TYPES.pg: MISTAKE_STATES.idle
    },
    actions: [
        {
            type: ACTION_TYPES.MISTAKE,
            state: ACTION_STATES.FINISH
        },
        {
            type: ACTION_TYPES.CARD,
            state: ACTION_STATES.FINISH
        },
        {
            type: ACTION_TYPES.CHAT,
            state: ACTION_STATES.FINISH
        },
        {
            type: ACTION_TYPES.CARD_CHANGE,
            state: ACTION_STATES.FINISH
        },
        {
            type: ACTION_TYPES.CHANGE,
            state: ACTION_STATES.FINISH
        },
        {
            type: ACTION_TYPES.TECHNICAL,
            state: ACTION_STATES.FINISH
        },

        {
            type: ACTION_TYPES.TALK,
            state: ACTION_STATES.FINISH
        },
        {
            type: ACTION_TYPES.PG,
            state: ACTION_STATES.FINISH
        }
    ],
    roundId: string,
    selected: false,
    tableName: string,
    dealerName: null,
    players: number,
    languageCode: "HY",
    totalAmount: number,
    currencyCode: string,
    gameStatus: {
        isActive: true,
        isPaused: false,
        timer: null
    }
 }
 **/

export default function reducer(state, action) {
    switch (action.type) {
        case types.INITIAL_PITBOSS_STATE: {
            const games = action.payload;
            return {
                games,
                loading: false,
                error: false
            };
        }
        case types.DEALER_CHANGE: {
            const { tableId, dealerNickname } = action.payload;

            let updatedGames = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, dealerNickname };
                }
                return game;
            });

            return {
                ...state,
                games: updatedGames
            };
        }
        case types.TABLE_NAME_CHANGE: {
            const { tableId, tableName } = action.payload;

            let updatedGames = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, tableName };
                }
                return game;
            });

            return {
                ...state,
                games: updatedGames
            };
        }
        case types.UPDATE_GAME_IMAGE: {
            return getTablesWithUpdatedImage({ state, payload: action.payload });
        }
        case types.TABLE_TOTAL_BET_OR_CURRENCY_CHANGED: {
            const { tableId, totalBetAmount, currencyCode } = action.payload;

            let updatedGames = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, totalBetAmount, currencyCode };
                }
                return game;
            });

            return {
                ...state,
                games: updatedGames
            };
        }
        case types.ROUND_ID_CHANGE: {
            const { tableId, roundId } = action.payload;

            let updatedGames = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, roundId };
                }
                return game;
            });

            return {
                ...state,
                games: updatedGames
            };
        }
        case types.GAME_SOUND_CHANGED: {
            const { tableId, isGameMuted } = action.payload;

            let updatedGames = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, isGameMuted };
                }
                return game;
            });

            return {
                ...state,
                games: updatedGames
            };
        }

        case types.ACTION_STATE_CHANGED: {
            const { tableId, type, state: mistakeState } = action.payload;
            const games = state.games.map(game => {
                if (game.tableId === tableId) {
                    let foundGame = { ...game };
                    foundGame.actions = [...foundGame.actions];
                    for (let action of foundGame.actions) {
                        if (action.type === type) {
                            action.state = mistakeState;
                        }
                    }
                    return foundGame;
                }

                return game;
            });

            return {
                ...state,
                games
            };
        }
        case types.PLAYERS_COUNT_CHANGE: {
            const { tableId, players, viewers, betters } = action.payload;

            let updatedGames = state.games.map(game => {
                if (game.tableId === tableId) {
                    game = { ...game, players, viewers, betters };
                }
                return game;
            });

            return {
                ...state,
                games: updatedGames
            };
        }
        case types.GAME_STATUS_CHANGED: {
            const { tableId, isActive, isPaused, timer } = action.payload;

            const games = state.games.map(game => {
                if (game.tableId === tableId) {
                    let newGame = { ...game };
                    newGame.gameStatus = {
                        isActive,
                        isPaused,
                        timer
                    };
                    return newGame;
                }

                return game;
            });

            return {
                ...state,
                games
            };
        }
        case types.TABLE_ADDED: {
            const { game } = action.payload;
            let games = [game, ...state.games];
            return {
                ...state,
                games
            };
        }
        case types.TABLE_REMOVED: {
            const { tableId } = action.payload;
            const games = state.games.filter(game => game.tableId !== tableId);
            return {
                ...state,
                games
            };
        }
        case types.ERROR: {
            const error = action.payload;
            return {
                ...state,
                error
            };
        }
        case types.DISCONNECT: {
            return INITIAL_STATE;
        }
        default:
            return state;
    }
}
