import React from "react";
import PropTypes from "prop-types";
import GameDetail from "./GameDetail";
import styles from "./index.module.scss";

const GameDetails = ({ children }) => {
    return <div className={styles.HeaderElements}>{children}</div>;
};

GameDetails.propTypes = {
    children: PropTypes.node.isRequired
};

GameDetails.Item = GameDetail;
export default GameDetails;
