import React, { useCallback, useState } from "react";
import { Form, Select } from "antd";
import PropTypes from "prop-types";
import { getLabel, required } from "shared/utils";
import MainModal from "./MainModal";
import styles from "components/Modals/styles.module.scss";

const SetOutcome = ({ onClose, onSubmit, winningOutcomes }) => {
    const [form] = Form.useForm();
    const [isSetNumberButtonDisabled, setIsSetNumberButtonDisabled] = useState(true);

    const handleOkClick = useCallback(() => {
        const formValues = form.getFieldsValue();
        const { outcomes } = formValues;
        onSubmit(outcomes);
    }, [onSubmit, form]);

    const onValuesChange = useCallback((_, allValues) => {
        const isButtonDisabled = !allValues.outcomes;
        setIsSetNumberButtonDisabled(isButtonDisabled);
    }, []);

    return (
        <MainModal
            onSubmit={handleOkClick}
            onClose={onClose}
            cancelButtonText="No"
            confirmButtonText="Yes"
            title="WINNING OUTCOME"
            confirmButtonDisabled={isSetNumberButtonDisabled}
        >
            <span>If you want to set an outcome, please input the required fields and click yes</span>
            <div className={styles.FormContainer}>
                <Form form={form} layout="vertical" onValuesChange={onValuesChange}>
                    <Form.Item label={getLabel("Available Outcomes")} name="outcomes" rules={[required("Outcome")]}>
                        <Select placeholder="Choose Outcome" className={styles.FormItem}>
                            {winningOutcomes.map(item => {
                                return (
                                    <Select.Option key={item} value={item}>
                                        {item}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </div>
        </MainModal>
    );
};

SetOutcome.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    winningOutcomes: PropTypes.array.isRequired
};

export default SetOutcome;
