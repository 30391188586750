/**
 *
 * @param prevActions
 * @param currentAction
 * @returns Array<Action>
 */
import queryString from "query-string";

export const updateActionsChange = (prevActions = [], currentAction) =>
    prevActions.map(action => {
        if (action.type === currentAction.type) {
            return { ...action, state: currentAction.state };
        }
        return action;
    });

export function getButtonsInitialStates(isGamePaused, isGameActive) {
    const mainButtonStates = {
        isCancelButtonDisabled: !isGamePaused, // cancel button must be enabled only when the game is paused
        isCloseButtonDisabled: false,
        isHistoryButtonDisabled: false,
        isMuteButtonDisabled: false,
        isPauseButtonDisabled: !isGameActive,
        isDealerLoginButtonDisabled: false
    };

    return mainButtonStates;
}

export const openRecordedStreamInNewTab = ({ recordedStreamUrl, tableName }) => {
    const streamURL = queryString.stringify({ recordedStreamUrl, tableName }, { encode: false });
    window.open(`${location.origin}?${streamURL}`);
};
