import React from "react";
import ClickableRoundId from "./index";
import { ROUND_STATES, ROUND_STATUSES } from "../../shared/constants";

export function roundIdRenderer({ columnData, rowData, baseUrlToRedirect }) {
    const status = rowData.roundStatus === ROUND_STATUSES.FINISH ? ROUND_STATES.SUCCESS : ROUND_STATES.WARNING;
    const roundId = rowData.roundId;

    function handleClick() {
        const redirectURL = `${baseUrlToRedirect}/${roundId}`;
        window.open(redirectURL);
    }

    return <ClickableRoundId status={status} text={columnData} onRoundIdClickCB={handleClick} />;
}
