import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { GAME_STATUSES } from "shared/constants";
import MainModal from "./MainModal";

const PauseOrResumeGame = ({ gameConfig, onClose, onSubmit }) => {
    const { isGamePaused } = gameConfig;

    const handleOkClick = useCallback(() => {
        const StatusId = isGamePaused ? GAME_STATUSES.Active : GAME_STATUSES.Paused;
        const gameStatusUpdateModel = { StatusId };
        onSubmit(gameStatusUpdateModel);
    }, [isGamePaused, onSubmit]);

    let title = isGamePaused ? "Resume Game" : "Pause Game";
    let message = isGamePaused
        ? "Are you sure you want to resume this game?"
        : "Are you sure you want to pause this game?";

    return (
        <MainModal
            onSubmit={handleOkClick}
            onClose={onClose}
            cancelButtonText="No"
            confirmButtonText="Yes"
            title={title}
        >
            {message}
        </MainModal>
    );
};

PauseOrResumeGame.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    gameConfig: PropTypes.shape({
        isGameMuted: PropTypes.bool,
        isGamePaused: PropTypes.bool,
        isGameActive: PropTypes.bool
    }).isRequired
};

export default PauseOrResumeGame;
