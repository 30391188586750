import React from "react";
import PropTypes from "prop-types";

const FullScreenPB = ({ color = "currentColor", isFullScreen = false }) => {
    let svg = (
        <svg fill={color} width="100%" height="100%" viewBox="0 0 20 17.98">
            <path
                d="M15,7H7v6h8Zm6-4H5A2.006,2.006,0,0,0,3,5V19a1.989,1.989,0,0,0,2,1.98H21A1.989,1.989,0,0,0,23,19V5A2.006,2.006,0,0,0,21,3Zm0,16.01H5V4.98H21Z"
                transform="translate(-3 -3)"
            />
        </svg>
    );

    if (isFullScreen) {
        svg = (
            <svg fill={color} width="100%" height="100%" viewBox="-4 -4 26.667 26.667">
                <path
                    d="M5,19.667H9v4h2.667V17H5ZM9,9H5v2.667h6.667V5H9Zm8,14.667h2.667v-4h4V17H17ZM19.667,9V5H17v6.667h6.667V9Z"
                    transform="translate(-5 -5)"
                />
            </svg>
        );
    }

    return svg;
};

FullScreenPB.propTypes = {
    color: PropTypes.string,
    isFullScreen: PropTypes.bool
};

export default FullScreenPB;
