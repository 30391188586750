import React, { useCallback, useEffect, useState } from "react";
import { Form, Select } from "antd";

import { getLabel, required, filterSelectOption, renderSelectDealerOptions } from "shared/utils";
import { dealerFieldData } from "shared/constants";

import MainModal from "./MainModal";

import PropTypes from "prop-types";

import styles from "components/Modals/styles.module.scss";

const REQUIRED_FIELDS_RULES = [required("This field")];

const DealerLogin = ({ onDealerLogin, onClose, dealerInfos, isDealerLoggedIn }) => {
    const [form] = Form.useForm();
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);
    const [isConfirmButtonClicked, setIsConfirmButtonClicked] = useState(false);

    const handleOkClick = useCallback(() => {
        const formValues = form.getFieldsValue();
        const { barcode } = formValues;

        onDealerLogin(barcode);
        setIsConfirmButtonClicked(true);
    }, [form, onDealerLogin]);

    useEffect(() => {
        if (!isDealerLoggedIn && isConfirmButtonClicked) {
            form.setFields(dealerFieldData);
        }
    }, [form, isDealerLoggedIn, isConfirmButtonClicked]);

    const onValuesChange = useCallback(allValues => {
        const isConfirmButtonDisabled = !allValues.barcode;

        setIsConfirmButtonClicked(false);
        setIsConfirmButtonDisabled(isConfirmButtonDisabled);
    }, []);

    return (
        <MainModal
            onSubmit={handleOkClick}
            onClose={onClose}
            cancelButtonText="Cancel"
            confirmButtonText="Login Dealer"
            title="Dealer Login"
            confirmButtonDisabled={isConfirmButtonDisabled}
        >
            <p>If you want to access the Dealer to this table, please enter the required fields</p>

            <div className={styles.PasswordField}>
                <Form form={form} layout="vertical" onValuesChange={(_, allValues) => onValuesChange(allValues)}>
                    <Form.Item label={getLabel("Dealer Nickname")} name="barcode" rules={REQUIRED_FIELDS_RULES}>
                        <Select
                            placeholder="Choose Dealer Nickname"
                            className={styles.FormItem}
                            showSearch
                            filterOption={filterSelectOption}
                            options={renderSelectDealerOptions(dealerInfos)}
                        />
                    </Form.Item>
                </Form>
            </div>
        </MainModal>
    );
};

DealerLogin.propTypes = {
    onDealerLogin: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    dealerInfos: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            nickname: PropTypes.string,
            surname: PropTypes.string,
            barcode: PropTypes.string
        })
    ).isRequired,
    isDealerLoggedIn: PropTypes.bool.isRequired
};

export default DealerLogin;
