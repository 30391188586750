import React, { useCallback, useState } from "react";
import { Form, Input } from "antd";
import PropTypes from "prop-types";
import { required, isEmptyStringOrNumberBetween0and36 } from "shared/utils";
import MainModal from "./MainModal";
import styles from "components/Modals/styles.module.scss";

const SetNumber = ({ onClose, roundId, onSubmit }) => {
    const [form] = Form.useForm();
    const [isSetNumberButtonDisabled, setIsSetNumberButtonDisabled] = useState(true);

    const handleOkClick = useCallback(() => {
        const formValues = form.getFieldsValue();
        const { number } = formValues;
        onSubmit(number);
    }, [onSubmit, form]);

    const onValuesChange = useCallback((_, allValues) => {
        const isButtonDisabled = !(parseInt(allValues.number) <= 36 && parseInt(allValues.number) >= 0);
        setIsSetNumberButtonDisabled(isButtonDisabled);
    }, []);

    const validateNumber = useCallback(async (rules, value) => {
        const isValueValidInput = isEmptyStringOrNumberBetween0and36(value);
        return new Promise((resolve, reject) => {
            if (isValueValidInput) {
                resolve();
            } else {
                reject("Invalid entry. Only numbers between 0-36 allowed.");
            }
        });
    }, []);

    return (
        <MainModal
            onSubmit={handleOkClick}
            onClose={onClose}
            cancelButtonText="Cancel"
            confirmButtonText="Set Number"
            title="WINNING NUMBER"
            confirmButtonDisabled={isSetNumberButtonDisabled}
        >
            <p>Round ID: {roundId}</p>
            <div className={styles.FormContainer}>
                <Form form={form} layout="vertical" onValuesChange={onValuesChange}>
                    <Form.Item name="number" rules={[required("Number"), { validator: validateNumber }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </div>
        </MainModal>
    );
};

SetNumber.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    roundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
SetNumber.defaultProps = {
    roundId: ""
};

export default SetNumber;
