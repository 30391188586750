import React from "react";
import PropTypes from "prop-types";

const CreateRow = ({ rows }) => rows.map((item, index) => <div key={index}>{item}</div>);

CreateRow.propTypes = {
    rows: PropTypes.array.isRequired
};

export default CreateRow;
